import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS, DIMENSIONS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  historyWrapper: {
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  historyContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginTop: '100px',
    marginBottom: '150px',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginTop: '80px',
      marginBottom: '80px',
    },
  },
  sectionTitle: {
    width: '750px',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  sectionText: {
    width: '650px',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'justify',
    },
  },
  sectionTitleCenter: {
    width: '300px',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  centerSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    marginBottom: '50px',
  },
  buttonsBox: {
    marginTop: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 15,
  },
  misionContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginTop: '50px',
    marginBottom: '150px',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginTop: '80px',
      marginBottom: '80px',
    },
  },
  valuesBox: {
    display: 'flex',
    gap: 50,
    [themeBreakpoints.down('sm')]: {
      flexWrap: 'wrap',
      gap: 30,
    },
  },
  valuesItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    width: '50%',
    [themeBreakpoints.down('md')]: {
      width: '100%',
    },
  },
  valuesIcon: {
    backgroundColor: COLORS.ligthCyan,
    width: '80px',
    height: '80px',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [themeBreakpoints.down('sm')]: {
      width: '60px',
      height: '60px',
    },
  },
  workItemsBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 100,
    [themeBreakpoints.down('sm')]: {
      gap: 50,
    },
  },
  workItem: {
    display: 'flex',
    gap: 40,
    justifyContent: 'space-between',
    [themeBreakpoints.down('sm')]: {
      gap: 20,
      flexWrap: 'wrap',
    },
  },
  workItemContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 10,
    width: '48%',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  workItemImgBox: {
    width: '53%',
    height: '500px',
    [themeBreakpoints.down('md')]: {
      height: '400px',
    },
    [themeBreakpoints.down('sm')]: {
      width: '100%',
      height: '350px',
    },
  },
  workItemImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '10px',
  },
  teamMembersBox: {
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    rowGap: 25,
    columnGap: 25,
    [themeBreakpoints.down('md')]: {
      justifyContent: 'space-evenly',
      columnGap: 0,
    },
    [themeBreakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  errorBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 20,
    marginBottom: '50px',
  },
  errorBoxInfo: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 5,
  },
}));

export default styles;
