import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../constants';
import breakpoints from '../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const fontsStyles = makeStyles((theme) => ({
  h1: {
    fontSize: 40,
    fontWeight: 600,
    color: COLORS.gray,
    lineHeight: '40px',
    fontFamily: ['Poppins'].join(','),
    margin: 0,
    marginBottom: '20px',
    [themeBreakpoints.down('sm')]: {
      fontSize: 28,
      lineHeight: '33px',
    },
  },
  h2: {
    fontSize: 32,
    fontWeight: 600,
    color: COLORS.gray,
    lineHeight: '32px',
    margin: 0,
    marginBottom: '20px',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 26,
      lineHeight: '36px',
    },
  },
  h3: {
    fontSize: 28,
    fontWeight: 600,
    margin: 0,
    marginBottom: '20px',
    color: COLORS.gray,
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  h4: {
    fontSize: 24,
    fontWeight: 600,
    color: COLORS.gray,
    lineHeight: '24px',
    margin: '0px',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  h5: {
    fontSize: 21,
    fontWeight: 600,
    color: COLORS.gray,
    lineHeight: '21px',
    margin: '0px',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  p: {
    margin: 0,
    fontSize: 16,
    fontWeight: 400,
    color: COLORS.gray,
    lineHeight: 'normal',
    whiteSpace: 'pre-line',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
      margin: 0,
      whiteSpace: 'normal',
    },
  },
  smallText: {
    margin: 0,
    fontSize: 12,
    fontWeight: 400,
    color: COLORS.white,
    lineHeight: '24px',
    whiteSpace: 'pre-line',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
      margin: 0,
      whiteSpace: 'normal',
    },
  },
  terms_conditionsFoms: {
    margin: 0,
    fontSize: 12,
    fontWeight: 300,
    color: COLORS.gray,
    lineHeight: '12px',
    whiteSpace: 'pre-line',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
      margin: 0,
      whiteSpace: 'normal',
    },
  },
  navbarItem: {
    margin: 0,
    fontSize: 16,
    fontWeight: 500,
    color: COLORS.gray,
    lineHeight: '24px',
    whiteSpace: 'pre-line',
    fontFamily: ['Poppins'].join(','),
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: COLORS.cyan,
    },
  },
  navbarItemActive: {
    color: COLORS.cyan,
  },
  footercolumnTitle: {
    margin: 0,
    fontSize: 16,
    fontWeight: 600,
    color: COLORS.white,
    lineHeight: '24px',
    whiteSpace: 'pre-line',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
      margin: 0,
      whiteSpace: 'normal',
    },
  },
  footercolumnText: {
    margin: 0,
    fontSize: 16,
    fontWeight: 400,
    color: COLORS.white,
    lineHeight: '24px',
    whiteSpace: 'pre-line',
    fontFamily: ['Poppins'].join(','),
    textDecoration: 'none',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    '&:hover': {
      color: COLORS.yellow,
    },
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
      whiteSpace: 'normal',
    },
  },
  sectionSubTitles: {
    margin: 0,
    fontSize: 16,
    fontWeight: 600,
    color: COLORS.cyan,
    lineHeight: 'normal',
    fontFamily: ['Poppins'].join(','),
    textTransform: 'uppercase',
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
      margin: 0,
      whiteSpace: 'normal',
    },
  },
  primaryButtonText: {
    margin: 0,
    fontSize: 16,
    fontWeight: 400,
    fontFamily: ['Poppins'].join(','),
    textDecoration: 'none',
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
      margin: 0,
    },
  },
  linkText: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: COLORS.cyan,
  },
  contactLinkText: {
    margin: 0,
    fontSize: 16,
    fontWeight: 400,
    color: COLORS.gray,
    lineHeight: '24px',
    whiteSpace: 'pre-line',
    fontFamily: ['Poppins'].join(','),
    textDecoration: 'none',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    '&:hover': {
      color: COLORS.cyan,
    },
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
      whiteSpace: 'normal',
    },
  },
  projectTitle: {
    fontSize: 22,
    fontWeight: 600,
    color: COLORS.gray,
    lineHeight: 'normal',
    margin: '0px',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  projectZones: {
    fontSize: 16,
    fontWeight: 600,
    color: COLORS.cyan,
    lineHeight: 'normal',
    margin: '0px',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  justify: {
    textAlign: 'justify',
  },

  // REVISAR
  noWrap: {
    whiteSpace: 'normal',
  },
  colorBlue: {
    color: '#0077FF',
  },
  footerButtonText: {
    margin: 0,
    fontSize: 16,
    fontWeight: 400,
    color: 'white',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
      margin: 0,
    },
  },
  footerButtonTextDisabled: {
    margin: 0,
    fontSize: 16,
    fontWeight: 400,
    color: 'gray',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
      margin: 0,
    },
  },
  titleInfo: {
    width: '70%',
    textAlign: 'justify',
    [themeBreakpoints.down('md')]: {
      width: '80%',
    },
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  a: {
    color: '#0077FF',
    textDecoration: 'none',
  },
  aGreen: {
    color: '#4EADB3',
    textDecoration: 'none',
  },
}));

export default fontsStyles;
