import { Box } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import CustomButton from '../../components/CustomButton';
import Metatags from '../../components/MetaTags/metatags';
import { PATHS } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';
const NotFound = () => {
  const classes = styles();
  const fontClasses = fontsStyles();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const Content = () => {
    return (
      <Box className={classes.contentWrapper}>
        <Box className={classes.secondContentWrapper}>
          <Box className={classes.contentHeaderContent}>
            <p className={fontClasses.sectionSubTitles}>¡OOPS!</p>
            <h2 className={fontClasses.h2}>{t('notFoundPage.title')}</h2>
            <p className={clsx(fontClasses.p, fontClasses.titleInfo, classes.pMessage)}>
              {t('notFoundPage.message')}
            </p>
          </Box>
          <Box className={classes.buttonsBox}>
            <CustomButton label={t('notFoundPage.primaryMessage')} url={PATHS.home} />
            <CustomButton
              label={t('notFoundPage.secondMessage')}
              type={'secondary'}
              url={PATHS.projects}
            />
          </Box>
        </Box>
      </Box>
    );
  };
  return (
    <>
      <Metatags
        title='CEMCA | Página no encontrada'
        description='Lo siento, la pagina no se encuentra en este sitio. Es posible que hayas ingresado una URL incorrecta o que la página haya sido eliminada.'
      />
      <Content />
    </>
  );
};

export default NotFound;
