import { gql, useQuery } from '@apollo/client';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomButton from '../../components/CustomButton';
import Metatags from '../../components/MetaTags/metatags';
import Seminar from '../../components/Seminar/index';
import { COLORS, PATHS } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

const COURSES_QUERY = gql`
  query Courses($title: String!, $orderBy: [String!]!, $language: I18NLocaleCode!) {
    courses(filters: { title: { containsi: $title } }, sort: $orderBy, locale: $language) {
      data {
        attributes {
          title
          modality
          enrollmentStart
          enrollmentEnd
          minimumCapacity
          phone_numbers
          price
          course_content
          course_tag
          duration
          dates
          form
          identification
          email
          createdAt
          updatedAt
          publishedAt
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
    }
  }
`;

const Seminars = () => {
  const classes = styles();
  const fontClasses = fontsStyles();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const Header = () => {
    return (
      <Box className={classes.seminarsWrapper}>
        <Box className={classes.seminarsContentWrapper}>
          <Box>
            <p className={fontClasses.sectionSubTitles}>{t('seminarsPage.headerSubTitle')}</p>
            <h1 className={clsx(fontClasses.h1, classes.sectionTitle)}>
              {t('seminarsPage.headerTitle')}
            </h1>
            <p className={clsx(fontClasses.p, classes.sectionText)}>
              {t('seminarsPage.headerText')}
            </p>
            <Box className={classes.buttonsBox}>
              <CustomButton label={t('seminarsPage.contact')} url={PATHS.contact} />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const SeminarContent = () => {
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('title');
    const {
      data: coursesList,
      loading,
      error,
    } = useQuery(COURSES_QUERY, {
      variables: {
        title: searchValue,
        orderBy: order,
        language: i18n.language,
      },
    });

    const handleChangeOrder = (event) => {
      setOrder(event.target.value);
    };

    return (
      <Box className={classes.seminarsListWrapper}>
        <Box className={classes.filtersWrapper}>
          <Box className={classes.searchBox}>
            <TextField
              fullWidth
              className={classes.inputText}
              variant='outlined'
              placeholder={t('seminarsPage.search')}
              value={searchValue}
              onChange={(e) => {
                e.preventDefault();
                setSearchValue(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon style={{ color: COLORS.gray }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box className={classes.filtersBox}>
            <Select
              autoWidth
              value={order}
              onChange={handleChangeOrder}
              startAdornment={
                <InputAdornment position='start'>
                  <ImportExportIcon style={{ color: COLORS.gray }} />
                  <p style={{ color: COLORS.gray }}>{t('seminarsPage.order')}:</p>
                </InputAdornment>
              }
              className={classes.select}
            >
              <MenuItem value={'title'}>
                <p className={fontClasses.p}>{t('seminarsPage.name')}</p>
              </MenuItem>
              <MenuItem value={'enrollmentEnd'}>
                <p className={fontClasses.p}>{t('seminarsPage.date')}</p>
              </MenuItem>
            </Select>
          </Box>
        </Box>
        <Box className={classes.seminarsListContentWrapper}>
          {loading && !error && (
            <Skeleton
              variant='rectangular'
              height={300}
              width={'100%'}
              sx={{ borderRadius: '10px', marginBottom: '50px' }}
            />
          )}
          {!loading &&
            !error &&
            coursesList &&
            coursesList.courses.data.map((seminar, index) => {
              return (
                <Box key={index} className={classes.seminarBox}>
                  <Seminar
                    ID={seminar.attributes.identification}
                    title={seminar.attributes.title}
                    img={seminar.attributes.image.data.attributes.url}
                    tag={seminar.attributes.course_tag}
                    startDate={seminar.attributes.dates}
                    modality={seminar.attributes.modality}
                    price={seminar.attributes.price}
                    duration={seminar.attributes.duration}
                    phone={seminar.attributes.phone_numbers}
                    mail={seminar.attributes.email}
                  />
                </Box>
              );
            })}
          {!loading && !error && coursesList && coursesList.courses.meta.pagination.total === 0 && (
            <Box className={classes.notFoundContent}>
              <h2 className={clsx(fontClasses.h2, classes.titleLabel)}>
                {t('seminarsPage.notFoundTitle')}
              </h2>
              <p className={clsx(fontClasses.p, classes.descriptionLabel)}>
                {t('seminarsPage.notFoundText')}
              </p>
              <CustomButton
                label={t('seminarsPage.projectButton')}
                url={PATHS.projects}
                type={'banner'}
              />
            </Box>
          )}
          {!loading && !coursesList && error && (
            <Box className={classes.errorBox}>
              <InfoOutlinedIcon sx={{ color: COLORS.gray, fontSize: '100px' }} />
              <Box className={classes.errorBoxInfo}>
                <h4 className={fontClasses.h4}>{t('validationsForm.errorTitle')}</h4>
                <p className={fontClasses.p}>{t('validationsForm.errorMessage')}</p>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box style={{ minHeight: '90vh', backgroundColor: 'white' }}>
      <Metatags
        title='CEMCA | Capacitaciones'
        description='Descubre nuestro catálogo de capacitaciones diseñadas para impulsar tu crecimiento profesional.'
      />
      <Header />
      <SeminarContent />
    </Box>
  );
};

export default Seminars;
