import { Box } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CustomButton from '../../components/CustomButton';
import { PATHS } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import useStyles from './styles';

const CoworkingBanner = () => {
  const classes = useStyles();
  const fontClasses = fontsStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.bannerWrapper}>
      <Box
        className={classes.bannerImageWrapper}
        sx={{
          display: {
            xs: 'flex',
            sm: 'flex',
            md: 'none',
            lg: 'flex',
            xl: 'flex',
          },
        }}
      >
        <img
          src={
            'https://res.cloudinary.com/sealabs/image/upload/v1703736263/cemca.co.cr/website/coursesBanner_ghlr3q.webp'
          }
          className={classes.bannerImage}
          alt={'Imagen de espacio de coworking'}
        />
      </Box>
      <Box className={classes.bannerContentWrapper}>
        <Box>
          <p className={fontClasses.sectionSubTitles}>{t('coursesBanner.headerSubTitle')}</p>
          <h2 className={clsx(fontClasses.h2, classes.sectionTitle)}>
            {t('coursesBanner.headerTitle')}
          </h2>
          <p className={clsx(fontClasses.p, classes.sectionText)}>
            {t('coursesBanner.headerText')}
          </p>
          <Box className={classes.buttonsBox}>
            <CustomButton
              label={t('coursesBanner.explore')}
              type={'banner'}
              url={PATHS.coworking}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CoworkingBanner;
