import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS, DIMENSIONS } from '../../constants/';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles((theme) => ({
  navbarWrapper: {
    backgroundColor: COLORS.white,
    height: '85px',
    width: '100%',
    zIndex: 500,
    display: 'flex',
    justifyContent: 'center',
    position: 'sticky',
    top: 35,
    boxShadow: '0px 10px 50px 0px rgba(66, 66, 66, 0.05)',
  },
  navbarContent: {
    width: DIMENSIONS.MAIN_WRAPPER,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      padding: '5px 10px',
    },
  },
  navBarLogo: {
    width: '100px',
  },
  navbarItems: {
    display: 'flex',
    alignItems: 'center',
    gap: 30,
  },
  navbarItemBox: {
    display: 'flex',
    alignItems: 'center',
    height: '85px',
    [themeBreakpoints.down('sm')]: {
      height: '50px',
      width: '100%',
    },
  },
  navbarItemBoxActive: {
    boxShadow: `inset 0px -5px 0px 0px ${COLORS.cyan}`,
    [themeBreakpoints.down('sm')]: {
      color: COLORS.cyan,
    },
  },
  hoverNavBoxWrapper: {
    position: 'fixed',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 500,
    top: 120,
  },
  hoverNavBoxContent: {
    backgroundColor: COLORS.white,
    width: '740px',
    height: '200px',
    boxShadow: '0px 50px 80px -10px rgba(66, 66, 66, 0.10)',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 60px',
  },
  removeDecoration: {
    textDecoration: 'none!important;',
    '&:hover $hoverNavBoxItemTitle': {
      color: COLORS.cyan,
    },
  },
  hoverNavBoxItem: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 20,
  },
  hoverNavBoxItemImage: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
  },
  hoverNavBoxItemTitle: {
    fontWeight: 500,
    marginBottom: '5px',
    '&:hover': {
      color: COLORS.cyan,
    },
  },
  hoverNavBoxItemText: {
    fontSize: '12px',
    width: '250px',
    height: '80px',
    margin: 0,
  },
  navbarItemButton: {
    backgroundColor: COLORS.yellow,
    padding: '10px 25px',
    borderRadius: 50,
    transition: 'all .2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  navbarItemButtonText: {
    fontSize: '16px',
    fontWeight: 400,
    textDecoration: 'none',
    color: COLORS.gray,
    fontFamily: ['Poppins'].join(','),
  },
  mobileDrawerBox: {
    position: 'fixed',
    width: '100%',
    backgroundColor: COLORS.ligthCyan,
    boxShadow: '0px 50px 80px -10px rgba(66, 66, 66, 0.10)',
    padding: '5px 10px',
    zIndex: 500,
    top: 120,
  },

  //  revisar
  navbarMobile: {
    justifyContent: 'flex-start',
    [themeBreakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },

  navbarHamburgerMenuBox: {
    position: 'absolute',
    top: 25,
    right: 5,
  },

  listDrawerBox: {
    width: '250px',
    marginTop: '100px',
    backgroundColor: COLORS.ligthCyan,
  },
  dropdownItem: {
    position: 'relative',
    '&:hover $dropdownAux': {
      visibility: 'visible',
    },
    '&:hover $dropdownBox': {
      visibility: 'visible',
    },
  },
  dropdownAux: {
    position: 'absolute',
    paddingTop: '10px',
    width: '70px',
    visibility: 'hidden',
  },
  dropdownBox: {
    visibility: 'hidden',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    backgroundColor: 'white',
    padding: '15px',
    width: '150px',
    boxShadow: '0px 10px 50px rgba(0, 0, 0, 0.3);',
  },
}));

export default useStyles;
