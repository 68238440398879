import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS, DIMENSIONS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  homeWrapper: {
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  homeContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginTop: '100px',
    marginBottom: '150px',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginTop: '80px',
      marginBottom: '80px',
    },
  },
  sectionTitle: {
    width: '750px',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  sectionTitleCenter: {
    width: '450px',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  sectionText: {
    width: '650px',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'justify',
    },
  },
  buttonsBox: {
    marginTop: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 15,
  },
  centerSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    marginBottom: '50px',
  },
  cardsBox: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [themeBreakpoints.down('md')]: {
      gap: 20,
    },
  },
  cardAboutBox: {
    backgroundColor: COLORS.cyan,
    width: '250px',
    height: '350px',
    boxSizing: 'border-box',
    borderRadius: '10px',
    padding: '30px',
    [themeBreakpoints.down('md')]: {
      width: '390px',
    },
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  cardAboutBox1: {
    backgroundImage:
      'url(https://res.cloudinary.com/sealabs/image/upload/v1703734615/cemca.co.cr/website/About/About1_x2ltue.png)',
    backgroundSize: 'cover',
  },
  cardAboutBox2: {
    backgroundImage:
      'url(https://res.cloudinary.com/sealabs/image/upload/v1703734616/cemca.co.cr/website/About/About2_ya01or.png)',
    backgroundSize: 'cover',
  },
  cardAboutBox3: {
    backgroundImage:
      'url(https://res.cloudinary.com/sealabs/image/upload/v1703734616/cemca.co.cr/website/About/About3_b5zqzz.png)',
    backgroundSize: 'cover',
  },
  cardAboutBox4: {
    backgroundImage:
      'url(https://res.cloudinary.com/sealabs/image/upload/v1703734616/cemca.co.cr/website/About/About4_rauvcc.png)',
    backgroundSize: 'cover',
  },
  cardTitle: {
    color: COLORS.white,
    fontWeight: 700,
  },
  cardText: {
    color: COLORS.white,
    fontWeight: 600,
    fontSize: '14px',
  },
  objectivesContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginTop: '0px',
    marginBottom: '200px',
    display: 'flex',
    flexWrap: 'wrap',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
      gap: 30,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginTop: '80px',
      marginBottom: '80px',
      gap: 25,
    },
  },
  objectivesTitleBox: {
    width: '40%',
    display: 'flex',
    alignItems: 'center',
    [themeBreakpoints.down('md')]: {
      width: '100%',
    },
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  objectivesSectionTitle: {
    width: '400px',
    [themeBreakpoints.down('md')]: {
      width: '100%',
    },
  },
  objectivesBox: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 50,
    [themeBreakpoints.down('md')]: {
      width: '100%',
    },
    [themeBreakpoints.down('sm')]: {
      width: '100%',
      gap: 40,
    },
  },
  objectiveItemBox: {
    boxSizing: 'border-box',
    display: 'flex',
    gap: 20,
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  objectiveIconBox: {
    backgroundColor: COLORS.ligthCyan,
    display: 'flex',
    width: '80px',
    height: '80px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    flexShrink: 0,
    [themeBreakpoints.down('sm')]: {
      width: '70px',
      height: '70px',
    },
  },
  objectiveTitle: {
    fontSize: '21px',
    fontWeight: 600,
    marginBottom: '5px',
  },
  objectiveTextBox: {
    display: 'flex',
    flexDirection: 'column',
    [themeBreakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  partnerTitle: {
    width: '450px',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  equalityContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '35px',
    alignItems: 'center',
    justifyContent: 'space-around',
    [themeBreakpoints.down('sm')]: {
      flexDirection: 'column',
      marginBottom: '80px',
    },
  },
  equalityTextWrapper: {
    maxWidth: '615px',
  },
  equalityTitle: {
    textAlign: 'center',
  },
  equalityContent: {
    textAlign: 'center',
  },
  equalityImagesWrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    maxHeight: '180px',
    width: '100%',
    marginTop: '50px',
    [themeBreakpoints.down('sm')]: {
      flexDirection: 'column',
      maxHeight: 'unset',
      alignItems: 'center',
      marginTop: '20px',
    },
  },
  awardIcon: {
    maxWidth: '280px',
    [themeBreakpoints.down('sm')]: {
      marginTop: '20px',
    },
  },
  equalityIcon: {
    maxWidth: '395px',
    [themeBreakpoints.down('sm')]: {
      marginBottom: '30px',
    },
  },
  logosSection: {
    marginTop: '50px',
  },
  logoContentBox: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: 20,
    flexWrap: 'wrap',
    [themeBreakpoints.down('sm')]: {
      gap: 30,
    },
  },
  logoBox: {
    width: '250px',
    height: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '20px',
    [themeBreakpoints.down('md')]: {
      width: '45%',
      height: '150px',
    },
    [themeBreakpoints.down('sm')]: {
      width: '100%',
      height: '150px',
      padding: '50px',
    },
  },
  logoImg: {
    width: '100%',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default styles;
