import 'mapbox-gl/dist/mapbox-gl.css';

import { gql, useQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PlaceIcon from '@mui/icons-material/Place';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Map, { Marker } from 'react-map-gl';
import { useMediaQuery } from 'react-responsive';

import CustomButton from '../../components/CustomButton';
import Member from '../../components/member/index';
import Metatags from '../../components/MetaTags/metatags';
import { COLORS, PATHS } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

const MEMBERS_QUERY = gql`
  query Members($page: Int!, $name: String!, $type: String!, $language: I18NLocaleCode!) {
    members(
      filters: { name: { containsi: $name }, and: { type: { contains: $type } } }
      pagination: { page: $page, pageSize: 12 }
      sort: "name:asc"
      locale: $language
    ) {
      data {
        attributes {
          name
          resume
          email
          telephone
          address
          address_coords
          schedule
          type
          logo {
            data {
              attributes {
                url
              }
            }
          }
          cover {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
      meta {
        pagination {
          page
          pageSize
          total
          pageCount
        }
      }
    }
  }
`;

const Directory = () => {
  const classes = styles();
  const fontClasses = fontsStyles();
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ query: '(min-width: 800px)' });
  const mapboxKey = process.env.REACT_APP_MAPBOX_TOKEN;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const Header = () => {
    return (
      <Box className={classes.afiliateWrapper}>
        <Box className={classes.afiliateontentWrapper}>
          <Box>
            <p className={fontClasses.sectionSubTitles}>{t('directoryPage.headerSubTitle')}</p>
            <h1 className={clsx(fontClasses.h1, classes.sectionTitle)}>
              {t('directoryPage.headerTitle')}
            </h1>
            <p className={clsx(fontClasses.p, classes.sectionText)}>
              {t('directoryPage.headerText')}
            </p>
            <Box className={classes.buttonsBox}>
              <CustomButton label={t('directoryPage.afiliate')} url={PATHS.affiliation} />
              <CustomButton
                label={t('directoryPage.contact')}
                url={PATHS.contact}
                type={'secondary'}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const DirectoryContent = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedMember, setSelectedMember] = useState();
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState('E');
    const {
      data: directoryList,
      loading,
      error,
    } = useQuery(MEMBERS_QUERY, {
      variables: {
        name: searchValue,
        page: currentPage,
        type: filter,
        language: i18n.language,
      },
    });

    const handleClose = () => {
      setModalOpen(false);
      setSelectedMember(null);
    };

    const handleClickMember = (member) => {
      setSelectedMember(member);
      setModalOpen(true);
    };

    const handleChangeFilter = (event) => {
      setFilter(event.target.value);
      setCurrentPage(1);
    };

    const handleChangePage = (event, value) => {
      setCurrentPage(value);
      window.scrollTo({
        top: 300,
        behavior: 'smooth',
      });
    };

    return (
      <>
        <Box className={classes.seminarsListWrapper}>
          <Box className={classes.filtersWrapper}>
            <Box className={classes.searchBox}>
              <TextField
                key='search'
                name='search'
                fullWidth
                className={classes.inputText}
                variant='outlined'
                placeholder={t('directoryPage.search')}
                value={searchValue}
                onChange={(e) => {
                  e.preventDefault();
                  setSearchValue(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon style={{ color: COLORS.gray }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box className={classes.filtersBox}>
              <Select
                autoWidth
                value={filter}
                onChange={handleChangeFilter}
                startAdornment={
                  <InputAdornment position='start'>
                    <ImportExportIcon style={{ color: COLORS.gray }} />
                    <p style={{ color: COLORS.gray }}>{t('directoryPage.filter')}:</p>
                  </InputAdornment>
                }
                className={classes.select}
              >
                <MenuItem value={'E'}>
                  <p className={fontClasses.p}>{t('directoryPage.all')}</p>
                </MenuItem>
                <MenuItem value={'Empresaria'}>
                  <p className={fontClasses.p}>{t('directoryPage.businessWoman')}</p>
                </MenuItem>
                <MenuItem value={'Emprendedora'}>
                  <p className={fontClasses.p}>{t('directoryPage.entrepreneur')}</p>
                </MenuItem>
              </Select>
            </Box>
          </Box>
          <Box className={classes.seminarsListContentWrapper}>
            {!directoryList && loading && !error && (
              <>
                <Skeleton
                  variant='rectangular'
                  height={400}
                  sx={{ borderRadius: '10px', width: { xs: '100%', sm: '100%', md: 350, lg: 350 } }}
                />
                <Skeleton
                  variant='rectangular'
                  height={400}
                  sx={{ borderRadius: '10px', width: { xs: '100%', sm: '100%', md: 350, lg: 350 } }}
                />
                <Skeleton
                  variant='rectangular'
                  height={400}
                  sx={{ borderRadius: '10px', width: { xs: '100%', sm: '100%', md: 350, lg: 350 } }}
                />
              </>
            )}
            {directoryList &&
              !error &&
              !loading &&
              directoryList.members.data.map((member, index) => {
                return (
                  <Box key={index}>
                    <Member
                      clickFunction={() => handleClickMember(member)}
                      name={member.attributes.name}
                      cover={member.attributes.cover.data.attributes.url}
                      logo={member.attributes.logo.data.attributes.url}
                      resume={member.attributes.resume}
                    />
                  </Box>
                );
              })}
          </Box>
          {!loading && !directoryList && error && (
            <Box className={classes.errorBox}>
              <InfoOutlinedIcon sx={{ color: COLORS.gray, fontSize: '100px' }} />
              <Box className={classes.errorBoxInfo}>
                <h4 className={fontClasses.h4}>{t('validationsForm.errorTitle')}</h4>
                <p className={fontClasses.p}>{t('validationsForm.errorMessage')}</p>
              </Box>
            </Box>
          )}
          {!loading &&
            !error &&
            directoryList &&
            directoryList.members.meta.pagination.total === 0 && (
              <Box className={classes.errorBox}>
                <InfoOutlinedIcon sx={{ color: COLORS.gray, fontSize: '100px' }} />
                <Box className={classes.errorBoxInfo}>
                  <h4 className={fontClasses.h4}>{t('validationsForm.notFound')}</h4>
                  <p className={fontClasses.p}>{t('validationsForm.notFoundMessage')}</p>
                </Box>
              </Box>
            )}
          {directoryList &&
            !error &&
            !loading &&
            directoryList.members.meta.pagination.total > 0 && (
              <Box className={classes.paginationBox}>
                <Pagination
                  count={directoryList.members.meta.pagination.pageCount}
                  page={currentPage}
                  onChange={handleChangePage}
                />
              </Box>
            )}
        </Box>
        {selectedMember && (
          <ContentModal member={selectedMember} pModalOpen={modalOpen} pHandleClose={handleClose} />
        )}
      </>
    );
  };

  const ContentModal = ({ member, pModalOpen, pHandleClose }) => {
    const Transition = React.forwardRef(function Transition(props, ref) {
      return <Grow timeout={{ appear: 5000, exit: 3000 }} ref={ref} {...props} />;
    });

    return (
      <Dialog
        TransitionComponent={Transition}
        open={pModalOpen}
        onClose={pHandleClose}
        fullWidth
        sx={{ zIndex: 1000, borderRadius: '0px' }}
        maxWidth='md'
        fullScreen={!isMobile}
      >
        <IconButton className={classes.modalCloseIcon} size='medium' onClick={pHandleClose}>
          <CloseIcon sx={{ fontSize: '25px', color: COLORS.gray }} />
        </IconButton>
        <Box className={classes.coverImgBox}>
          <img src={member.attributes.cover.data.attributes.url} className={classes.coverImg} />
        </Box>
        <Box className={classes.modalBox}>
          <Box className={classes.modalTitleBox}>
            <h3 className={fontClasses.h3} style={{ marginBottom: '5px' }}>
              {member.attributes.name}
            </h3>
            <Chip
              label={
                member.attributes.type === 'Emprendedora'
                  ? t('directoryPage.entrepreneur')
                  : t('directoryPage.businessWoman')
              }
              sx={{ marginBottom: '15px' }}
            />
            <p className={fontClasses.p}>{member.attributes.resume}</p>
          </Box>
          <Box>
            {member.attributes.schedule && (
              <Box className={classes.modalItemContent}>
                <ScheduleIcon sx={{ color: COLORS.gray, fontSize: '25px' }} />
                <Box>
                  <p className={fontClasses.p}>
                    <b>{t('directoryPage.schedule')}:</b>
                  </p>
                  <p className={fontClasses.p}>{member.attributes.schedule}</p>
                </Box>
              </Box>
            )}
            {member.attributes.telephone && (
              <Box className={classes.modalItemContent}>
                <LocalPhoneIcon sx={{ color: COLORS.gray, fontSize: '25px' }} />
                <Box>
                  <p className={fontClasses.p}>
                    <b>{t('directoryPage.phone')}:</b>
                  </p>
                  <p className={fontClasses.p}>
                    <a
                      href={`tel:${member.attributes.telephone}`}
                      style={{ textDecoration: 'none', color: COLORS.gray }}
                    >
                      {member.attributes.telephone}
                    </a>
                  </p>
                </Box>
              </Box>
            )}
            {member.attributes.email && (
              <Box className={classes.modalItemContent}>
                <EmailIcon sx={{ color: COLORS.gray, fontSize: '25px' }} />
                <Box>
                  <p className={fontClasses.p}>
                    <b>{t('directoryPage.email')}:</b>
                  </p>
                  <p className={fontClasses.p}>
                    <a
                      href={`mailto:${member.attributes.email}`}
                      style={{ textDecoration: 'none', color: COLORS.gray }}
                    >
                      {member.attributes.email}
                    </a>
                  </p>
                </Box>
              </Box>
            )}
            {member.attributes.address && (
              <Box className={classes.modalItemContent}>
                <LocationOnIcon sx={{ color: COLORS.gray, fontSize: '25px' }} />
                <Box>
                  <p className={fontClasses.p}>
                    <b>{t('directoryPage.address')}:</b>
                  </p>
                  <p className={fontClasses.p}>{member.attributes.address}</p>
                </Box>
              </Box>
            )}
            {member.attributes.address_coords && (
              <Box>
                <Map
                  mapboxAccessToken={mapboxKey}
                  initialViewState={{
                    longitude: member.attributes.address_coords.longitude,
                    latitude: member.attributes.address_coords.latitude,
                    zoom: 14,
                  }}
                  style={{ width: '100%', height: '250px', borderRadius: '10px' }}
                  mapStyle='mapbox://styles/jeanvegad/clnfkgnl1083001qx6dbdg9r7'
                  attributionControl={false}
                  logoPosition='bottom-right'
                >
                  <Marker
                    longitude={member.attributes.address_coords.longitude}
                    latitude={member.attributes.address_coords.latitude}
                    anchor='bottom'
                  >
                    <PlaceIcon style={{ fontSize: '40px', color: COLORS.cyan }} />
                  </Marker>
                </Map>
              </Box>
            )}
          </Box>
        </Box>
      </Dialog>
    );
  };

  return (
    <Box>
      <Metatags
        title='CEMCA | Directorio de afiliadas'
        description='Visite nuestro directorio de empresas afiliadas.'
      />
      <Header />
      <DirectoryContent />
    </Box>
  );
};

export default React.memo(Directory);
