import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS, DIMENSIONS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  afiliateWrapper: {
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  afiliateContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginTop: '100px',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginTop: '80px',
      marginBottom: '50px',
    },
  },
  centerSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  sectionTitleCenter: {
    width: '750px',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  sectionText: {
    width: '750px',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'justify',
    },
  },
  formWrapper: {
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  formContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginTop: '50px',
    marginBottom: '150px',
    boxSizing: 'border-box',
    borderRadius: '10px',
    padding: '80px',
    boxShadow: '0px 0px 20px 5px rgba(159, 159, 159, 0.20)',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      padding: '15px',
      marginTop: '0px',
      marginBottom: '80px',
    },
  },
  formSection: {
    marginBottom: '50px',
    [themeBreakpoints.down('sm')]: {
      marginBottom: '25px',
    },
  },
  formText: {
    marginBottom: '25px',
    [themeBreakpoints.down('sm')]: {
      marginBottom: '15px',
    },
  },
  sectionFormTitle: {
    color: COLORS.cyan,
    marginBottom: '15px',
  },
  formColumns: {
    display: ' flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  inputTextBox: {
    marginBottom: '15px',
  },
  titleInput: {
    fontWeight: 600,
    marginBottom: '5px',
  },
  inputColumn: {
    width: '49%',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: 15,
  },
}));

export default styles;
