import Box from '@mui/material/Box';
import clsx from 'clsx';
import React from 'react';

import fontsStyles from '../../styles/fontStyles';
import useStyles from './styles';

const Member = ({ clickFunction, name, cover, logo, resume }) => {
  const fontClasses = fontsStyles();
  const classes = useStyles();

  return (
    <Box className={classes.memberBox} onClick={clickFunction}>
      <Box className={classes.coverImgBox}>
        <img src={cover} className={classes.coverImg} />
      </Box>
      <Box className={classes.memberInformartion}>
        <Box className={classes.logoImgBox}>
          <img src={logo} className={classes.logoImg} />
        </Box>
        <p className={clsx(fontClasses.p, classes.memberTitle)}>{name}</p>
        <p className={clsx(fontClasses.p, classes.memberText)}>{resume}</p>
      </Box>
    </Box>
  );
};

export default Member;
