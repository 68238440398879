import 'mapbox-gl/dist/mapbox-gl.css';

import { gql, useMutation } from '@apollo/client';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PlaceIcon from '@mui/icons-material/Place';
import { Alert, Box, Snackbar, TextField } from '@mui/material';
import clsx from 'clsx';
import { useFormik } from 'formik';
import mapboxgl from 'mapbox-gl'; // This is a dependency of react-map-gl even if you didn't explicitly install it
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Map, { Marker } from 'react-map-gl';
import * as yup from 'yup';

import CustomFunctionButton from '../../components/CustomFunctionButton';
import Metatags from '../../components/MetaTags/metatags';
import { COLORS } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

// Graph mutation
const ADD_CONTACT_FORM_MUTATION = gql`
  mutation AddContactForm($email: String!, $name: String!, $phone: String!, $message: String!) {
    createContactForm(data: { email: $email, name: $name, phone: $phone, message: $message }) {
      data {
        id
        attributes {
          email
          name
          phone
          message
        }
      }
    }
  }
`;

const Contact = () => {
  const classes = styles();
  const fontClasses = fontsStyles();
  const { t } = useTranslation();
  const mapboxKey = process.env.REACT_APP_MAPBOX_TOKEN;
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [addContactForm, { data, loading, error }] = useMutation(ADD_CONTACT_FORM_MUTATION);
  const handleOpenSuccess = () => {
    setOpenSuccess(true);
  };

  const handleCloseSuccess = (event) => {
    setOpenSuccess(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const Header = () => {
    const validationSchema = yup.object({
      name: yup.string().required(t('validationsForm.required')),
      phone: yup.string().required(t('validationsForm.required')),
      email: yup
        .string()
        .email(t('validationsForm.validEmail'))
        .required(t('validationsForm.required')),
      message: yup.string().required(t('validationsForm.required')),
    });

    useEffect(() => {
      if (!loading && !error && data) {
        handleOpenSuccess();
        formik.resetForm();
      }
    }, [data]);

    const formik = useFormik({
      initialValues: {
        name: '',
        phone: '',
        email: '',
        message: '',
      },
      validationSchema,
      onSubmit: async (values) => {
        await addContactForm({
          variables: { ...values },
        });
      },
    });
    return (
      <Box className={classes.contactrapper}>
        <Metatags
          title='CEMCA | Contáctenos'
          description='¿Alguna pregunta o recomendación? Sólo llena el formulario en nuestra página y envíanos un mensaje.'
        />
        <Box className={classes.contactContentWrapper}>
          <Box className={classes.infoBox}>
            <Box>
              <p className={fontClasses.sectionSubTitles}>{t('contactPage.headerSubTitle')}</p>
              <h1 className={fontClasses.h1}>{t('contactPage.headerTitle')}</h1>
              <p className={fontClasses.p}>{t('contactPage.headerText')}</p>
            </Box>
            <Box>
              <Box className={classes.linksItemsBox}>
                <Box>
                  <a
                    href={
                      'https://www.google.com/maps/place/Lim%C3%B3n/@9.9918421,-83.0602886,14z/data=!3m1!4b1!4m5!3m4!1s0x8fa705367150d903:0x24d864fd050c815f!8m2!3d9.9913106!4d-83.0415078'
                    }
                    className={fontClasses.contactLinkText}
                  >
                    <LocationOnIcon />
                    Entrada a Barrio Veracruz, Limón, CR.
                  </a>
                  <a href={'tel:+50627582169'} className={fontClasses.contactLinkText}>
                    <LocalPhoneIcon />
                    +506 27582169
                  </a>
                  <a
                    href={'mailto: cemcalimoncr@gmail.com'}
                    className={fontClasses.contactLinkText}
                  >
                    <EmailIcon />
                    cemcalimoncr@gmail.com
                  </a>
                </Box>
                <Box className={classes.socialBox}>
                  <a
                    href='https://www.facebook.com/profile.php?id=100056245969007'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <p className={classes.socialIcon}>
                      <FacebookIcon style={{ fontSize: '25px' }} />
                    </p>
                  </a>
                  <a href='https://www.instagram.com/cemcacr' target='_blank' rel='noreferrer'>
                    <p className={classes.socialIcon}>
                      <InstagramIcon style={{ fontSize: '25px' }} />
                    </p>
                  </a>
                </Box>
              </Box>
              <Box>
                <Map
                  mapboxAccessToken={mapboxKey}
                  initialViewState={{
                    longitude: -83.03392807600892,
                    latitude: 10.00401438151683,
                    zoom: 14,
                  }}
                  style={{ width: '100%', height: '300px', borderRadius: '10px' }}
                  mapStyle='mapbox://styles/jeanvegad/clnfkgnl1083001qx6dbdg9r7'
                  attributionControl={false}
                  logoPosition='bottom-right'
                >
                  <Marker
                    longitude={-83.03392807600892}
                    latitude={10.00401438151683}
                    anchor='bottom'
                  >
                    <PlaceIcon style={{ fontSize: '40px', color: COLORS.cyan }} />
                  </Marker>
                </Map>
              </Box>
            </Box>
          </Box>
          <Box className={classes.formBox}>
            <form onSubmit={formik.handleSubmit}>
              <p className={clsx(fontClasses.p, classes.formTitle)}>{t('contactPage.formTitle')}</p>
              {Object.keys(formik.errors).length > 0 && formik.submitCount > 0 && (
                <Box sx={{ marginTop: '15px', marginBottom: '15px' }}>
                  <Alert severity='error'>{t('validationsForm.complete')}</Alert>
                </Box>
              )}
              {error && (
                <Box sx={{ marginTop: '15px', marginBottom: '15px' }}>
                  <Alert severity='error'>{t('validationsForm.serverError')}</Alert>
                </Box>
              )}
              <Box className={classes.inputTextBox}>
                <p className={clsx(fontClasses.p, classes.titleInput)}>{t('contactPage.name')}</p>
                <TextField
                  fullWidth
                  variant='outlined'
                  id='name'
                  name='name'
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Box>
              <Box className={classes.inputTextBox}>
                <p className={clsx(fontClasses.p, classes.titleInput)}>{t('contactPage.phone')}</p>
                <TextField
                  fullWidth
                  variant='outlined'
                  id='phone'
                  name='phone'
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </Box>
              <Box className={classes.inputTextBox}>
                <p className={clsx(fontClasses.p, classes.titleInput)}>{t('contactPage.email')}</p>
                <TextField
                  fullWidth
                  className={classes.inputText}
                  variant='outlined'
                  id='email'
                  name='email'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Box>
              <Box className={classes.inputTextBox}>
                <p className={clsx(fontClasses.p, classes.titleInput)}>
                  {t('contactPage.message')}
                </p>
                <TextField
                  fullWidth
                  variant='outlined'
                  multiline
                  rows={4}
                  id='message'
                  name='message'
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  error={formik.touched.message && Boolean(formik.errors.message)}
                  helperText={formik.touched.message && formik.errors.message}
                />
              </Box>
              <Box className={classes.buttonBox}>
                <CustomFunctionButton label={t('contactPage.submitButton')} />
              </Box>
              <p className={clsx(fontClasses.smallText, classes.termsText)}>
                {t('contactPage.conditions')}
              </p>
            </form>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Header />
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSuccess} severity='success' sx={{ width: '100%' }}>
          {t('contactPage.succesMessage')}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Contact;
