// import ContactPageIcon from "@mui/icons-material/ContactPage";
import { ContactPage } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import MenuIcon from '@mui/icons-material/Menu';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SchoolIcon from '@mui/icons-material/School';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation } from 'react-router-dom';

import Logo from '../../assets/img/logo-cemca-cyan.png';
import VectorCapacitacion from '../../assets/img/vector-capacitaciones.png';
import VectorCatalogo from '../../assets/img/vector-catalago.png';
import VectorCoworking from '../../assets/img/vector-coworking.png';
import VectorFormulario from '../../assets/img/vector-formulario.png';
import { COLORS, PATHS } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import useStyles from './styles';

export default function NavbarWrapper(props) {
  const classes = useStyles();
  const fontClasses = fontsStyles();
  const location = useLocation();
  const [pathname, setPathname] = useState('');
  const [servicesHover, setServicesHover] = useState(false);
  const [affiliatesHover, setaffiliatesHover] = useState(false);
  const [mobileDrawer, setaMobileDrawer] = useState(false);
  const isDesktop = useMediaQuery({ query: '(min-width: 1100px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 800px)' });
  const isMobile = useMediaQuery({ query: '(min-width: 400px)' });
  const { t } = useTranslation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname, title: document.title });
  }, [location]);

  const openDrawer = () => {
    setaMobileDrawer(true);
  };

  const closeDrawer = () => {
    setaMobileDrawer(false);
  };

  const mouseEnterAffiliates = () => {
    setaffiliatesHover(true);
    setServicesHover(false);
  };

  const mouseEnterServices = () => {
    setServicesHover(true);
    setaffiliatesHover(false);
  };

  const mouseLeaveAffiliates = () => {
    setaffiliatesHover(false);
  };

  const mouseLeaveServices = () => {
    setServicesHover(false);
  };

  const cleanHovers = () => {
    setaffiliatesHover(false);
    setServicesHover(false);
  };

  useEffect(() => {
    if (isDesktop) {
      setaMobileDrawer(false);
    } else if (isTablet) {
      setaMobileDrawer(false);
    } else {
      cleanHovers();
    }
  }, [isDesktop, isTablet, isMobile]);

  useEffect(() => {
    setPathname(location.pathname);
    setaMobileDrawer(false);
    cleanHovers();
  }, [location]);

  const MobileDrawer = () => {
    return (
      <Grow in={mobileDrawer}>
        <Box className={classes.mobileDrawerBox}>
          <Box className={classes.navbarItemBox}>
            <ListItemIcon className={classes.listItemIcon} sx={{ minWidth: '35px' }} suze>
              <Diversity2Icon
                sx={{
                  color: pathname === PATHS.history ? COLORS.cyan : COLORS.gray,
                  fontSize: '20px',
                }}
              />
            </ListItemIcon>
            <Link
              className={clsx(
                fontClasses.navbarItem,
                pathname === PATHS.history ? fontClasses.navbarItemActive : '',
              )}
              to={PATHS.history}
              onClick={closeDrawer}
            >
              {t('navbar.history')}
            </Link>
          </Box>
          <Box className={classes.navbarItemBox}>
            <ListItemIcon className={classes.listItemIcon} sx={{ minWidth: '35px' }} suze>
              <PostAddIcon
                sx={{
                  color: pathname === PATHS.affiliation ? COLORS.cyan : COLORS.gray,
                  fontSize: '25px',
                }}
              />
            </ListItemIcon>
            <Link
              className={clsx(
                fontClasses.navbarItem,
                pathname === PATHS.affiliation ? fontClasses.navbarItemActive : '',
              )}
              to={PATHS.affiliation}
              onClick={closeDrawer}
            >
              {t('navbar.affiliation')}
            </Link>
          </Box>
          <Box className={classes.navbarItemBox}>
            <ListItemIcon className={classes.listItemIcon} sx={{ minWidth: '35px' }} suze>
              <LocalLibraryIcon
                sx={{
                  color: pathname === PATHS.directory ? COLORS.cyan : COLORS.gray,
                  fontSize: '20px',
                }}
              />
            </ListItemIcon>
            <Link
              className={clsx(
                fontClasses.navbarItem,
                pathname === PATHS.directory ? fontClasses.navbarItemActive : '',
              )}
              to={PATHS.directory}
              onClick={closeDrawer}
            >
              {t('navbar.affiliationList')}
            </Link>
          </Box>
          <Box className={classes.navbarItemBox}>
            <ListItemIcon className={classes.listItemIcon} sx={{ minWidth: '35px' }} suze>
              <FolderSpecialIcon
                sx={{
                  color: pathname === PATHS.projects ? COLORS.cyan : COLORS.gray,
                  fontSize: '20px',
                }}
              />
            </ListItemIcon>
            <Link
              className={clsx(
                fontClasses.navbarItem,
                pathname === PATHS.projects ? fontClasses.navbarItemActive : '',
              )}
              to={PATHS.projects}
              onClick={closeDrawer}
            >
              {t('navbar.projects')}
            </Link>
          </Box>
          <Box className={classes.navbarItemBox}>
            <ListItemIcon className={classes.listItemIcon} sx={{ minWidth: '35px' }} suze>
              <ConnectWithoutContactIcon
                sx={{
                  color: pathname === PATHS.coworking ? COLORS.cyan : COLORS.gray,
                  fontSize: '20px',
                }}
              />
            </ListItemIcon>
            <Link
              className={clsx(
                fontClasses.navbarItem,
                pathname === PATHS.coworking ? fontClasses.navbarItemActive : '',
              )}
              to={PATHS.coworking}
              onClick={closeDrawer}
            >
              {t('navbar.coworking')}
            </Link>
          </Box>
          <Box className={classes.navbarItemBox}>
            <ListItemIcon className={classes.listItemIcon} sx={{ minWidth: '35px' }} suze>
              <SchoolIcon
                sx={{
                  color: pathname === PATHS.courses ? COLORS.cyan : COLORS.gray,
                  fontSize: '20px',
                }}
              />
            </ListItemIcon>
            <Link
              className={clsx(
                fontClasses.navbarItem,
                pathname === PATHS.courses ? fontClasses.navbarItemActive : '',
              )}
              to={PATHS.courses}
              onClick={closeDrawer}
            >
              {t('navbar.courses')}
            </Link>
          </Box>
          <Box className={classes.navbarItemBox}>
            <ListItemIcon className={classes.listItemIcon} sx={{ minWidth: '35px' }} suze>
              <ContactPage
                sx={{
                  color: pathname === '/contacto' ? COLORS.cyan : COLORS.gray,
                  fontSize: '20px',
                }}
              />
            </ListItemIcon>
            <Link
              className={clsx(
                fontClasses.navbarItem,
                pathname === '/contacto' ? fontClasses.navbarItemActive : '',
              )}
              to={'/contacto'}
              onClick={closeDrawer}
            >
              {t('navbar.contact')}
            </Link>
          </Box>
        </Box>
      </Grow>
    );
  };

  const AffiliatesHover = () => {
    return (
      <Grow in={affiliatesHover} onMouseLeave={mouseLeaveAffiliates}>
        <Box className={classes.hoverNavBoxWrapper}>
          <Box className={classes.hoverNavBoxContent}>
            <Link to={PATHS.affiliation} className={classes.removeDecoration}>
              <Box className={classes.hoverNavBoxItem}>
                <Box className={classes.hoverNavBoxItemImage}>
                  <img src={VectorFormulario} alt='Imagen de formulario' width={55} height={55} />
                </Box>
                <Box>
                  <p className={clsx(fontClasses.p, classes.hoverNavBoxItemTitle)}>
                    {t('navbar.affiliation')}
                  </p>
                  <p className={clsx(fontClasses.p, classes.hoverNavBoxItemText)}>
                    {t('navbar.affiliationText')}
                  </p>
                </Box>
              </Box>
            </Link>
            <Link to={PATHS.directory} className={classes.removeDecoration}>
              <Box className={classes.hoverNavBoxItem}>
                <Box className={classes.hoverNavBoxItemImage}>
                  <img src={VectorCatalogo} alt='Imagen de catalogo' width={60} height={60} />
                </Box>
                <Box>
                  <p className={clsx(fontClasses.p, classes.hoverNavBoxItemTitle)}>
                    {t('navbar.affiliationList')}
                  </p>
                  <p className={clsx(fontClasses.p, classes.hoverNavBoxItemText)}>
                    {t('navbar.affiliationListText')}
                  </p>
                </Box>
              </Box>
            </Link>
          </Box>
        </Box>
      </Grow>
    );
  };

  const ServicesHover = () => {
    return (
      <Grow in={servicesHover} onMouseLeave={mouseLeaveServices}>
        <Box className={classes.hoverNavBoxWrapper}>
          <Box className={classes.hoverNavBoxContent}>
            <Link to={PATHS.coworking} className={classes.removeDecoration} onClick={cleanHovers}>
              <Box className={classes.hoverNavBoxItem}>
                <Box className={classes.hoverNavBoxItemImage}>
                  <img src={VectorCoworking} alt='Imagen de coworking' width={80} height={80} />
                </Box>
                <Box>
                  <p className={clsx(fontClasses.p, classes.hoverNavBoxItemTitle)}>
                    {t('navbar.coworking')}
                  </p>
                  <p className={clsx(fontClasses.p, classes.hoverNavBoxItemText)}>
                    {t('navbar.coworkingDescription')}
                  </p>
                </Box>
              </Box>
            </Link>
            <Link to={PATHS.courses} className={classes.removeDecoration} onClick={cleanHovers}>
              <Box className={classes.hoverNavBoxItem}>
                <Box className={classes.hoverNavBoxItemImage}>
                  <img
                    src={VectorCapacitacion}
                    alt='Imagen de capacitaciones'
                    width={60}
                    height={60}
                  />
                </Box>
                <Box>
                  <p className={clsx(fontClasses.p, classes.hoverNavBoxItemTitle)}>
                    {t('navbar.courses')}
                  </p>
                  <p className={clsx(fontClasses.p, classes.hoverNavBoxItemText)}>
                    {t('navbar.coursesDescription')}
                  </p>
                </Box>
              </Box>
            </Link>
          </Box>
        </Box>
      </Grow>
    );
  };

  return (
    <>
      <Box className={classes.navbarWrapper}>
        <Box className={classes.navbarContent}>
          <Box>
            <Link to='/'>
              <img className={classes.navBarLogo} src={Logo} alt='CEMCA logo' />
            </Link>
          </Box>
          <Box
            className={classes.navbarItems}
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'flex',
                lg: 'flex',
                xl: 'flex',
              },
            }}
          >
            <Box
              className={clsx(
                classes.navbarItemBox,
                pathname === PATHS.history ? classes.navbarItemBoxActive : '',
              )}
              onMouseEnter={cleanHovers}
            >
              <Link className={fontClasses.navbarItem} to={PATHS.history}>
                {t('navbar.history')}
              </Link>
            </Box>
            <Box
              className={clsx(
                classes.navbarItemBox,
                [PATHS.affiliation, PATHS.directory].includes(pathname)
                  ? classes.navbarItemBoxActive
                  : '',
              )}
            >
              <p className={fontClasses.navbarItem} onMouseEnter={mouseEnterAffiliates}>
                {t('navbar.affiliates')}
              </p>
            </Box>
            <Box
              className={clsx(
                classes.navbarItemBox,
                pathname === PATHS.projects ? classes.navbarItemBoxActive : '',
              )}
              onMouseEnter={cleanHovers}
            >
              <Link className={fontClasses.navbarItem} to={PATHS.projects}>
                {t('navbar.projects')}
              </Link>
            </Box>
            <Box
              className={clsx(
                classes.navbarItemBox,
                [PATHS.coworking, PATHS.courses].includes(pathname)
                  ? classes.navbarItemBoxActive
                  : '',
              )}
            >
              <p className={fontClasses.navbarItem} onMouseEnter={mouseEnterServices}>
                {t('navbar.services')}
              </p>
            </Box>
          </Box>
          <Box
            className={classes.navbarItemButton}
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'flex',
                lg: 'flex',
                xl: 'flex',
              },
            }}
          >
            <Link to={PATHS.contact} className={classes.navbarItemButtonText}>
              {t('navbar.contact')}
            </Link>
          </Box>
          <Box
            sx={{
              display: {
                xs: 'flex',
                sm: 'flex',
                md: 'none',
                lg: 'none',
                xl: 'none',
              },
            }}
          >
            {!mobileDrawer && (
              <Grow in={!mobileDrawer} {...(!mobileDrawer ? { timeout: 300 } : {})}>
                <IconButton aria-label='delete' onClick={openDrawer}>
                  <MenuIcon style={{ fontSize: 30, color: COLORS.cyan }} />
                </IconButton>
              </Grow>
            )}
            {mobileDrawer && (
              <Grow in={mobileDrawer} {...(mobileDrawer ? { timeout: 300 } : {})}>
                <IconButton aria-label='delete' onClick={closeDrawer}>
                  <CloseIcon style={{ fontSize: 30, color: COLORS.cyan }} />
                </IconButton>
              </Grow>
            )}
          </Box>
        </Box>
      </Box>
      <MobileDrawer />
      <AffiliatesHover />
      <ServicesHover />
    </>
  );
}
