import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import React from 'react';
import { Link } from 'react-router-dom';

import { COLORS } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import useStyles from './styles';

const CustomButton = ({ label, url, type }) => {
  const fontClasses = fontsStyles();
  const classes = useStyles();
  let boxClass;
  let arrowColor;
  if (type === 'secondary') {
    boxClass = classes.buttonSecondaryBox;
    arrowColor = COLORS.cyan;
  } else if (type === 'banner') {
    boxClass = classes.buttonBannerBox;
    arrowColor = COLORS.gray;
  } else {
    boxClass = classes.buttonPrimaryBox;
    arrowColor = COLORS.white;
  }

  return (
    <Box className={boxClass}>
      <Link to={url} className={classes.linkStyle}>
        <p className={fontClasses.primaryButtonText} style={{ color: arrowColor }}>
          {label}
        </p>
        <ArrowForwardIcon style={{ color: arrowColor }} />
      </Link>
    </Box>
  );
};

export default CustomButton;
