import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { DIMENSIONS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  seminarPageWrapper: {
    backgroundColor: 'white',
    minHeight: '90vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
      paddingTop: '850px',
    },
  },
  seminarPageContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginTop: '100px',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginTop: '50px',
    },
  },
  seminarContentBox: {
    marginBottom: '50px',
  },
  formSpacing: {
    marginBottom: '25px',
  },
  titleContent: {
    marginBottom: '25px',
  },
  textContent: {
    textAlign: 'justify',
    '& li': {
      marginLeft: '20px',
    },
  },
  seminarFormWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  seminarFormContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginBottom: '100px',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '50px',
    },
  },
  googleFormStyle: {
    width: '100%',
    height: '2100px',
    [themeBreakpoints.down('md')]: {
      height: '2900px',
    },
  },
}));

export default styles;
