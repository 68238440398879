import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles((theme) => ({
  buttonPrimaryBox: {
    backgroundColor: COLORS.cyan,
    border: `solid 2px ${COLORS.cyan}`,
    borderRadius: '50px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
      opacity: '0.9',
    },
  },
  buttonSecondaryBox: {
    border: `solid 2px ${COLORS.cyan}`,
    borderRadius: '50px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
      opacity: '0.9',
    },
  },
  buttonBannerBox: {
    border: `solid 2px ${COLORS.gray}`,
    borderRadius: '50px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
      opacity: '0.9',
    },
  },
  linkStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    gap: 20,
    width: 'auto',
    padding: '10px 20px',
    borderRadius: '50px',
    [themeBreakpoints.down('sm')]: {
      gap: 15,
      padding: '10px 15px',
    },
  },

  buttonMainStyleDisabled: {
    textDecoration: 'none',
    backgroundColor: '#e0e0e0',
    padding: '10px 20px',
    width: '100%',
    height: '45px',
    border: 'solid 2px #e0e0e0',
    borderRadius: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    cursor: 'not-allowed',
    transition: 'all 0.3s ease',
    opacity: 1,
    color: 'black',
  },
  iconButtonBox: {
    padding: '5px',
    backgroundColor: 'rgba(0,0,0,0)',
    borderRadius: '50%',
    border: 'splid',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
