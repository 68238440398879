import { gql, useMutation } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import PlaceIcon from '@mui/icons-material/Place';
import {
  Alert,
  Box,
  Dialog,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import clsx from 'clsx';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Map, { Marker } from 'react-map-gl';
import { useMediaQuery } from 'react-responsive';
import * as yup from 'yup';

import CustomButton from '../../components/CustomButton';
import CustomFunctionButton from '../../components/CustomFunctionButton';
import Metatags from '../../components/MetaTags/metatags';
import { COLORS, PATHS } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

dayjs.extend(utc);
dayjs.extend(timezone);

// GraphQL Mutuation
const ADD_COWORKING_FORM_MUTATION = gql`
  mutation AddCoworkingForm(
    $ocupationDate: Date!
    $name: String!
    $company: String!
    $email: String!
    $phone: String!
    $spaceType: String!
    $ocupationTime: String!
  ) {
    createCoworkingForm(
      data: {
        ocupationDate: $ocupationDate
        name: $name
        company: $company
        email: $email
        phone: $phone
        spaceType: $spaceType
        ocupationTime: $ocupationTime
      }
    ) {
      data {
        id
        attributes {
          ocupationDate
          name
          company
          email
          phone
          spaceType
          ocupationTime
        }
      }
    }
  }
`;

const Coworking = () => {
  const classes = styles();
  const fontClasses = fontsStyles();
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [addCoWorkingForm, { data, loading, error }] = useMutation(ADD_COWORKING_FORM_MUTATION);
  const isMobile = useMediaQuery({ query: '(min-width: 800px)' });
  const spacesRefBox = useRef(null);
  const mapboxKey = process.env.REACT_APP_MAPBOX_TOKEN;

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const handleOpenSuccess = () => setOpenSuccess(true);
  const handleCloseSuccess = (event) => setOpenSuccess(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const scrollToSpaces = () => {
    spacesRefBox.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const Header = () => {
    return (
      <Box className={classes.coworkingWrapper}>
        <Box className={classes.coworkingContentWrapper}>
          <Box>
            <p className={fontClasses.sectionSubTitles}>{t('coworkingPage.headerSubTitle')}</p>
            <h1 className={clsx(fontClasses.h1, classes.sectionTitle)}>
              {t('coworkingPage.headerTitle')}
            </h1>
            <p className={clsx(fontClasses.p, classes.sectionText)}>
              {t('coworkingPage.headerText')}
            </p>
            <Box className={classes.buttonsBox}>
              <CustomFunctionButton
                label={t('coworkingPage.saveSpace')}
                handlerFun={scrollToSpaces}
              />
              <CustomButton
                label={t('coworkingPage.contact')}
                type={'secondary'}
                url={PATHS.contact}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const CoworkingInfo = () => {
    return (
      <Box className={classes.coworkingInfoWrapper}>
        <Box className={classes.coworkingInfoContentWrapper}>
          <Box className={classes.coworkingInfoColumn}>
            <h4 className={clsx(fontClasses.p, classes.infoTitle)}>
              {t('coworkingPage.infoTitle1')}
            </h4>
            <p className={clsx(fontClasses.p, classes.infoText)}>{t('coworkingPage.intfoText1')}</p>
          </Box>
          <Box className={classes.coworkingInfoColumn}>
            <h4 className={clsx(fontClasses.p, classes.infoTitle)}>
              {t('coworkingPage.infoTitle2')}
            </h4>
            <p className={clsx(fontClasses.p, classes.infoText)}>{t('coworkingPage.intfoText2')}</p>
          </Box>
        </Box>
      </Box>
    );
  };

  const CoworkingSpaces = () => {
    return (
      <Box className={classes.coworkingWrapper}>
        <Box className={classes.coworkingContentWrapper}>
          <Box className={classes.centerSection}>
            <p className={fontClasses.sectionSubTitles}>{t('coworkingPage.spacesTitle1')}</p>
            <h1 className={clsx(fontClasses.h2, classes.sectionTitleCenter)}>
              {t('coworkingPage.spacesSubtitle1')}
            </h1>
          </Box>
          <Box className={classes.spaceBox} ref={spacesRefBox}>
            <img
              src='https://res.cloudinary.com/sealabs/image/upload/v1703648906/cemca.co.cr/website/output_image_1_lcytlo.webp'
              className={classes.image}
            />
            <Box className={classes.contentSpace}>
              <h4 className={clsx(fontClasses.h4, classes.spacesTitle)}>
                {t('coworkingPage.privateOffice')}
              </h4>
              <p className={clsx(fontClasses.p, classes.bottomSpace)}>
                {t('coworkingPage.privateOfficeText')}
              </p>
              <p className={clsx(fontClasses.p, classes.bottomSpace)}>
                <b>{t('coworkingPage.privateOfficeIncludes')}</b>
              </p>
              <p className={clsx(fontClasses.p, classes.bottomSpace)}>
                {t('coworkingPage.privateOfficeBenefits')}
              </p>
              <p className={clsx(fontClasses.p, fontClasses.linkText)} onClick={handleOpen}>
                {t('coworkingPage.availability')}
              </p>
            </Box>
          </Box>
          <Box className={classes.spaceBox}>
            <img
              src='https://res.cloudinary.com/sealabs/image/upload/v1703649733/cemca.co.cr/website/coworking_meetingRoom_cirlpu.webp'
              className={classes.image}
            />
            <Box className={classes.contentSpace}>
              <h4 className={clsx(fontClasses.h4, classes.spacesTitle)}>
                {t('coworkingPage.meetingRoom')}
              </h4>
              <p className={clsx(fontClasses.p, classes.bottomSpace)}>
                {t('coworkingPage.meetingRoomText')}
              </p>
              <p className={clsx(fontClasses.p, classes.bottomSpace)}>
                <b>{t('coworkingPage.meetingRoomIncludes')}</b>
              </p>
              <p className={clsx(fontClasses.p, classes.bottomSpace)}>
                {t('coworkingPage.meetingRoomBenefits')}
              </p>
              <p className={clsx(fontClasses.p, fontClasses.linkText)} onClick={handleOpen}>
                {t('coworkingPage.availability')}
              </p>
            </Box>
          </Box>
          <Box className={classes.spaceBox} sx={{ marginBottom: '0px' }}>
            <img
              src='https://res.cloudinary.com/sealabs/image/upload/v1703649733/cemca.co.cr/website/coworking_classRoom_mh2a4m.webp'
              className={classes.image}
            />
            <Box className={classes.contentSpace}>
              <h4 className={clsx(fontClasses.h4, classes.spacesTitle)}>
                {t('coworkingPage.classRoom')}
              </h4>
              <p className={clsx(fontClasses.p, classes.bottomSpace)}>
                {t('coworkingPage.classRoomText')}
              </p>
              <p className={clsx(fontClasses.p, classes.bottomSpace)}>
                <b>{t('coworkingPage.classRoomIncludes')}</b>
              </p>
              <p className={clsx(fontClasses.p, classes.bottomSpace)}>
                {t('coworkingPage.classRoomBenefits')}
              </p>
              <p className={clsx(fontClasses.p, fontClasses.linkText)} onClick={handleOpen}>
                {t('coworkingPage.availability')}
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const Location = () => {
    return (
      <Box className={classes.coworkingWrapper}>
        <Box className={classes.coworkingContentWrapper} sx={{ marginTop: '50px' }}>
          <Box>
            <p className={fontClasses.sectionSubTitles}>{t('coworkingPage.locationSubTitle')}</p>
            <h2 className={clsx(fontClasses.h2, classes.sectionTitle)}>
              {t('coworkingPage.locationTitle')}
            </h2>
            <p className={clsx(fontClasses.p, classes.sectionLocationText)}>
              {t('coworkingPage.locationText')}
            </p>
            <Box className={classes.buttonsBox}>
              <Map
                mapboxAccessToken={mapboxKey}
                initialViewState={{
                  longitude: -83.03392807600892,
                  latitude: 10.00401438151683,
                  zoom: 14,
                }}
                style={{ width: '100%', height: '400px', borderRadius: '10px' }}
                mapStyle='mapbox://styles/jeanvegad/clnfkgnl1083001qx6dbdg9r7'
                attributionControl={false}
                logoPosition='bottom-right'
              >
                <Marker longitude={-83.03392807600892} latitude={10.00401438151683} anchor='bottom'>
                  <PlaceIcon style={{ fontSize: '40px', color: COLORS.cyan }} />
                </Marker>
              </Map>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const BookingModal = () => {
    const validationSchema = yup.object({
      ocupationDate: yup.string().required(t('validationsForm.required')),
      ocupationTime: yup.string().required(t('validationsForm.required')),
      spaceType: yup.string().required(t('validationsForm.required')),
      name: yup.string().required(t('validationsForm.required')),
      company: yup.string().required(t('validationsForm.required')),
      phone: yup.string().required(t('validationsForm.required')),
      email: yup
        .string()
        .email(t('validationsForm.validEmail'))
        .required(t('validationsForm.required')),
    });
    useEffect(() => {
      if (!loading && !error && data) {
        handleOpenSuccess();
        formik.resetForm();
      }
    }, [data]);
    const formik = useFormik({
      initialValues: {
        ocupationDate: null,
        ocupationTime: '',
        spaceType: '',
        name: '',
        company: '',
        phone: '',
        email: '',
      },
      validationSchema,
      onSubmit: async (values) => {
        console.log(values);
        const formattedDate = dayjs(values.ocupationDate).format('YYYY-MM-DD'); // Format the date here
        await addCoWorkingForm({ variables: { ...values, ocupationDate: formattedDate } });
      },
    });

    return (
      <Dialog
        open={modalOpen}
        onClose={handleClose}
        fullWidth
        sx={{ zIndex: 1000 }}
        maxWidth='md'
        fullScreen={!isMobile}
      >
        {!isMobile && (
          <Box className={classes.mobileModalHeader}>
            <h3 className={fontClasses.h3} style={{ margin: 0, color: COLORS.white }}>
              {t('coworkingModal.modalTitle')}
            </h3>
            <IconButton size='medium' onClick={handleClose}>
              <CloseIcon sx={{ fontSize: '30px', color: COLORS.white }} />
            </IconButton>
          </Box>
        )}
        <form onSubmit={formik.handleSubmit}>
          <Box className={classes.modalBox}>
            {isMobile && <h2 className={fontClasses.h2}>{t('coworkingModal.modalTitle')}</h2>}
            {isMobile && (
              <IconButton className={classes.modalCloseIcon} size='medium' onClick={handleClose}>
                <CloseIcon sx={{ fontSize: '30px', color: COLORS.gray }} />
              </IconButton>
            )}
            <p className={fontClasses.p} style={{ marginBottom: '20px' }}>
              {t('coworkingModal.modalText')}
            </p>
            {Object.keys(formik.errors).length > 0 && formik.submitCount > 0 && (
              <Box sx={{ marginBottom: '20px' }}>
                <Alert severity='error'>{t('validationsForm.complete')}</Alert>
              </Box>
            )}
            {error && (
              <Box sx={{ marginBottom: '20px' }}>
                <Alert severity='error'>{t('validationsForm.serverError')}</Alert>
              </Box>
            )}
            <Box className={classes.modalColumns}>
              <Box className={clsx(classes.inputTextBox, classes.inputColumn)}>
                <p className={clsx(fontClasses.p, classes.titleInput)}>
                  {t('coworkingModal.reservationDate')}
                </p>
                <DesktopDatePicker
                  sx={{ width: '100%' }}
                  disablePast
                  orientation='portrait'
                  autoFocus={false}
                  timezone='America/Costa_Rica'
                  value={formik.values.ocupationDate}
                  onChange={(value) => formik.setFieldValue('ocupationDate', value, true)}
                  slotProps={{
                    textField: {
                      name: 'ocupationDate',
                      error: formik.touched.ocupationDate && Boolean(formik.errors.ocupationDate),
                      helperText: formik.touched.ocupationDate && formik.errors.ocupationDate,
                    },
                  }}
                />
              </Box>
              <Box className={clsx(classes.inputTextBox, classes.inputColumn)}>
                <p className={clsx(fontClasses.p, classes.titleInput)}>
                  {t('coworkingModal.reservationTime')}
                </p>
                <FormControl
                  error={formik.touched.ocupationTime && Boolean(formik.errors.ocupationTime)}
                  fullWidth
                >
                  <Select
                    fullWidth
                    variant='outlined'
                    id='ocupationTime'
                    name='ocupationTime'
                    value={formik.values.ocupationTime}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={'Medio día'}>{t('coworkingModal.halfDay')}</MenuItem>
                    <MenuItem value={'Día completo'}>{t('coworkingModal.fullDay')}</MenuItem>
                    <MenuItem value={'Horario especial'}>
                      {t('coworkingModal.specialTime')}
                    </MenuItem>
                  </Select>
                  <FormHelperText>
                    {formik.touched.ocupationTime && formik.errors.ocupationTime}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Box>
            <Box className={classes.inputTextBox}>
              <p className={clsx(fontClasses.p, classes.titleInput)}>
                {t('coworkingModal.spaceType')}
              </p>
              <FormControl
                error={formik.touched.spaceType && Boolean(formik.errors.spaceType)}
                fullWidth
              >
                <Select
                  fullWidth
                  variant='outlined'
                  id='spaceType'
                  name='spaceType'
                  value={formik.values.spaceType}
                  onChange={formik.handleChange}
                >
                  <MenuItem value={'Oficina privada'}>{t('coworkingPage.privateOffice')}</MenuItem>
                  <MenuItem value={'Sala de reuniones'}>{t('coworkingPage.meetingRoom')}</MenuItem>
                  <MenuItem value={'Salón de clases'}>{t('coworkingPage.classRoom')}</MenuItem>
                </Select>
                <FormHelperText>
                  {formik.touched.spaceType && formik.errors.spaceType}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box className={classes.inputTextBox}>
              <p className={clsx(fontClasses.p, classes.titleInput)}>{t('coworkingModal.name')}</p>
              <TextField
                fullWidth
                className={classes.inputText}
                variant='outlined'
                id='name'
                name='name'
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Box>
            <Box className={classes.inputTextBox}>
              <p className={clsx(fontClasses.p, classes.titleInput)}>
                {t('coworkingModal.company')}
              </p>
              <TextField
                fullWidth
                className={classes.inputText}
                variant='outlined'
                id='company'
                name='company'
                value={formik.values.company}
                onChange={formik.handleChange}
                error={formik.touched.company && Boolean(formik.errors.company)}
                helperText={formik.touched.company && formik.errors.company}
              />
            </Box>
            <Box className={classes.modalColumns}>
              <Box className={clsx(classes.inputTextBox, classes.inputColumn)}>
                <p className={clsx(fontClasses.p, classes.titleInput)}>
                  {t('coworkingModal.phone')}
                </p>
                <TextField
                  fullWidth
                  className={classes.inputText}
                  variant='outlined'
                  id='phone'
                  name='phone'
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </Box>
              <Box className={clsx(classes.inputTextBox, classes.inputColumn)}>
                <p className={clsx(fontClasses.p, classes.titleInput)}>
                  {t('coworkingModal.email')}
                </p>
                <TextField
                  fullWidth
                  className={classes.inputText}
                  variant='outlined'
                  id='email'
                  name='email'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Box>
            </Box>
            <Box className={classes.modalButtons}>
              <CustomFunctionButton label={t('coworkingModal.button')} />
              <p className={clsx(fontClasses.terms_conditionsFoms)}>{t('coworkingModal.terms')}</p>
            </Box>
          </Box>
        </form>
      </Dialog>
    );
  };

  return (
    <Box>
      <Metatags
        title='CEMCA | Espacios de trabajo'
        description='Reúnete en espacios de primer nivel y eleva la presencia de tu empresa o emprendimiento.'
      />
      <Header />
      <CoworkingInfo />
      <CoworkingSpaces />
      <Location />
      <BookingModal />
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSuccess} severity='success' sx={{ width: '100%' }}>
          {t('contactPage.succesMessage')}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Coworking;
