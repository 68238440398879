import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS, DIMENSIONS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  coworkingWrapper: {
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  coworkingContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginTop: '100px',
    marginBottom: '150px',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginTop: '80px',
      marginBottom: '80px',
    },
  },
  sectionTitle: {
    width: '750px',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  sectionText: {
    width: '650px',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'justify',
    },
  },
  sectionLocationText: {
    width: '800px',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'justify',
    },
  },
  buttonsBox: {
    marginTop: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 15,
  },
  coworkingInfoWrapper: {
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  coworkingInfoContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginTop: '50px',
    marginBottom: '100px',
    backgroundColor: COLORS.ligthCyan,
    borderRadius: '10px',
    padding: '60px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: 25,
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      gap: 50,
      padding: '25px',
      width: '90%',
      marginTop: '80px',
      marginBottom: '80px',
    },
  },
  coworkingInfoColumn: {
    width: '48%',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  infoTitle: {
    fontSize: '21px',
    fontWeight: 600,
    marginBottom: '20px',
  },
  infoText: {
    textAlign: 'justify',
  },
  centerSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    marginBottom: '50px',
  },
  sectionTitleCenter: {
    width: '450px',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  spaceBox: {
    display: 'flex',
    gap: 50,
    flexWrap: 'wrap',
    marginBottom: '100px',
    [themeBreakpoints.down('sm')]: {
      gap: 25,
      marginBottom: '50px',
    },
  },
  image: {
    width: '40%',
    height: '450px',
    objectFit: 'cover',
    borderRadius: '10px',
    [themeBreakpoints.down('md')]: {
      width: '100%',
    },
    [themeBreakpoints.down('sm')]: {
      height: '350px',
      width: '100%',
    },
  },
  contentSpace: {
    width: '55%',
    [themeBreakpoints.down('md')]: {
      width: '100%',
    },
  },
  spacesTitle: {
    marginBottom: '20px',
    [themeBreakpoints.down('sm')]: {
      marginBottom: '15px',
    },
  },
  bottomSpace: {
    marginBottom: '15px',
  },
  modalBox: {
    padding: '50px',
    [themeBreakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  modalColumns: {
    display: ' flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  inputTextBox: {
    marginBottom: '15px',
  },
  titleInput: {
    fontWeight: 600,
    marginBottom: '5px',
  },
  inputColumn: {
    width: '49%',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  modalButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: 15,
  },
  modalCloseIcon: {
    position: 'absolute !important',
    top: 45,
    right: 50,
  },
  mobileModalHeader: {
    backgroundColor: COLORS.cyan,
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default styles;
