import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS, DIMENSIONS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles((theme) => ({
  bannerWrapper: {
    backgroundColor: COLORS.ligthCyan,
    height: '600px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      flexWrap: 'wrap',
      height: 'auto',
      padding: '25px 0',
      gap: 25,
    },
  },
  bannerImageWrapper: {
    position: 'absolute',
    left: '0',
    width: '45%',
    height: '600px',
    [themeBreakpoints.down('sm')]: {
      position: 'unset',
      width: '90%',
      height: 'auto',
    },
  },
  bannerImage: {
    width: '100%',
    objectFit: 'cover',
    [themeBreakpoints.down('sm')]: {
      borderRadius: '10px',
    },
  },
  bannerContentWrapper: {
    boxSizing: 'border-box',
    width: DIMENSIONS.MAIN_WRAPPER,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
    },
  },
  sectionTitle: {
    width: '600px',
    [themeBreakpoints.down('md')]: {
      width: '100%',
    },
  },
  sectionText: {
    width: '600px',
    [themeBreakpoints.down('md')]: {
      width: '100%',
      textAlign: 'justify',
    },
  },
  buttonsBox: {
    marginTop: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 15,
  },
}));

export default useStyles;
