import 'dayjs/locale/es';
import 'dayjs/locale/en';

import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Footer from './components/Footer/index.js';
import Navbar from './components/Navbar';
import SubNavbar from './components/SubNavbar';
import WhatsappWidget from './components/WhatsappWidget/index.js';
import { PATHS } from './constants/index.js';
import Affiliation from './pages/afiliation/index.js';
import Contact from './pages/contact/index.js';
import Coworking from './pages/coworking/index.js';
import Directory from './pages/directory/index.js';
import History from './pages/history/index.js';
import Home from './pages/home/index.js';
import NotFound from './pages/notFound/index.js';
import Projects from './pages/projects/index';
import SeminarPage from './pages/SeminarPage/index';
import Seminars from './pages/Seminars/index';
import getTheme from './theme';

const App = () => {
  const theme = getTheme();
  const { i18n } = useTranslation();
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

  const httpLink = new HttpLink({ uri: process.env.REACT_APP_STRAPI_GRAPHQL });

  const authLink = new ApolloLink((operation, forward) => {
    // Use the setContext method to set the HTTP headers.
    operation.setContext({
      headers: {
        authorization: `Bearer ${process.env.REACT_APP_STRAPI_ACCESS_TOKEN}`,
      },
    });

    // Call the next link in the middleware chain.
    return forward(operation);
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink), // Chain it with the HttpLink
    cache: new InMemoryCache(),
  });

  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
          <Router>
            <SubNavbar />
            <Navbar />
            <WhatsappWidget number='+50686955800' />
            <Switch>
              <Route exact path={PATHS.home} component={Home} />
              <Route exact path={PATHS.history} component={History} />
              <Route exact path={PATHS.projects} component={Projects} />
              <Route exact path={PATHS.coworking} component={Coworking} />
              <Route exact path={PATHS.affiliation} component={Affiliation} />
              <Route exact path={PATHS.directory} component={Directory} />
              <Route exact path={PATHS.courses} component={Seminars} />
              <Route exact path={`${PATHS.courses}/:id`} component={SeminarPage} />
              <Route exact path={PATHS.contact} component={Contact} />
              <Route exact path={PATHS.notFound} component={NotFound} />
            </Switch>
            <Footer />
          </Router>
        </LocalizationProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
};

export default App;
