import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS, DIMENSIONS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  projectWrapper: {
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  projectContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginTop: '100px',
    marginBottom: '150px',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginTop: '80px',
      marginBottom: '80px',
    },
  },
  sectionTitle: {
    width: '750px',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  sectionText: {
    width: '650px',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'justify',
    },
  },
  buttonsBox: {
    marginTop: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 15,
  },
  loadingWrapper: {
    backgroundColor: COLORS.ligthCyan,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  loadingContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginTop: '100px',
    marginBottom: '100px',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginTop: '80px',
      marginBottom: '80px',
    },
  },
  loadingBox: {
    display: 'flex',
    gap: 50,
    [themeBreakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  loadingContentBox: {
    width: '50%',
    [themeBreakpoints.down('md')]: {
      width: '100%',
    },
  },
  loadingImageBox: {
    width: '50%',
    [themeBreakpoints.down('md')]: {
      width: '100%',
    },
  },
  errorBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
  },
  errorBoxInfo: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 5,
  },
}));

export default styles;
