import { Box } from '@mui/material';
import React from 'react';

import useStyles from './styles';

const AddsBanner = () => {
  const classes = useStyles();

  return (
    <Box className={classes.bannerWrapper}>
      <Box
        className={classes.bannerImageWrapper}
        sx={{
          display: {
            xs: 'flex',
            sm: 'flex',
            md: 'flex',
            lg: 'flex',
            xl: 'flex',
          },
        }}
      >
        <img
          src={
            'https://res.cloudinary.com/sealabs/image/upload/v1731645453/cemca.co.cr/website/Empodera_caribe_jdu3gl.jpg'
          }
          className={classes.bannerImage}
          alt={'Imagen de anuncio'}
        />
      </Box>
    </Box>
  );
};

export default AddsBanner;
