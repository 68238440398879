import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS, DIMENSIONS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  afiliateWrapper: {
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  afiliateontentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginTop: '100px',
    marginBottom: '150px',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginTop: '80px',
      marginBottom: '80px',
    },
  },
  seminarsListWrapper: {
    backgroundColor: COLORS.ligthCyan,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
      marginTop: '50px',
    },
  },
  seminarsListContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    display: 'flex',
    gap: 20,
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginBottom: '50px',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginBottom: '80px',
      flexDirection: 'column',
    },
  },
  filtersWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    backgroundColor: COLORS.white,
    borderRadius: '10px',
    position: 'relative',
    padding: '30px',
    top: -55,
    boxShadow: ' 0px 0px 20px 0px rgba(159, 159, 159, 0.20)',
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      gap: 10,
    },
  },
  searchBox: {
    width: '50%',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  filtersBox: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-end',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'start',
    },
  },
  sectionTitle: {
    width: '750px',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  sectionText: {
    width: '650px',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'justify',
    },
  },
  buttonsBox: {
    marginTop: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 15,
  },
  paginationBox: {
    marginBottom: '100px',
    [themeBreakpoints.down('sm')]: {
      marginTop: '0px',
      marginBottom: '50px',
    },
  },
  errorBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 20,
    marginBottom: '50px',
  },
  errorBoxInfo: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 5,
  },
  modalBox: {
    padding: '50px',
    [themeBreakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  modalCloseIcon: {
    backgroundColor: 'white !important',
    position: 'absolute !important',
    top: 30,
    right: 30,
  },
  coverImgBox: {
    with: '100%',
  },
  coverImg: {
    width: '100%',
    height: '400px',
    objectFit: 'cover',
    borderTopLeftRadius: '10px',
  },
  modalTitleBox: {
    marginBottom: '20px',
  },
  modalItemContent: { display: 'flex', marginBottom: '20px', gap: 10 },
  chip: {
    backgroundColor: COLORS.cyan,
    color: COLORS.white,
    padding: '10px',
    with: '100%',
  },
}));

export default styles;
