import { gql, useQuery } from '@apollo/client';
import { Box, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import { Markup } from 'interweave';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import Metatags from '../../components/MetaTags/metatags';
import SeminarPageHeader from '../../components/SeminarPageHeader';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

const COURSES_QUERY = gql`
  query Courses($identification: String!, $language: I18NLocaleCode!) {
    courses(filters: { identification: { eq: $identification } }, locale: $language) {
      data {
        attributes {
          title
          modality
          enrollmentStart
          enrollmentEnd
          minimumCapacity
          phone_numbers
          price
          course_content
          course_tag
          duration
          dates
          form
          identification
          email
          createdAt
          updatedAt
          publishedAt
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
    }
  }
`;

const SeminarPage = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = styles();
  const fontClasses = fontsStyles();
  const [seminar, setSeminar] = useState();
  const { t, i18n } = useTranslation();
  const {
    data: coursesList,
    loading,
    error,
  } = useQuery(COURSES_QUERY, {
    variables: {
      identification: id,
      language: i18n.language,
    },
  });

  useEffect(() => {
    if (error) {
      console.log('error', error);
      history.push('/capacitaciones');
    }
    if (coursesList) {
      if (coursesList.courses.meta.pagination.total === 0) {
        history.push('/capacitaciones');
      } else {
        setSeminar(coursesList.courses.data[0]);
      }
    }
  }, [loading]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const SeminarContent = () => {
    return (
      <Box className={classes.seminarPageWrapper}>
        <Box className={classes.seminarPageContentWrapper}>
          <Grid container spacing={6}>
            <Grid item md={7} sm={12}>
              {seminar.attributes.course_content.map((item, index) => (
                <Box key={index} className={classes.seminarContentBox}>
                  <h4 className={clsx(fontClasses.h4, classes.titleContent)}>{item[0]}</h4>
                  <Markup content={item[1]} className={clsx(fontClasses.p, classes.textContent)} />
                </Box>
              ))}
              {seminar.attributes.form && seminar.attributes.form !== ' ' && <GoogleFormContent />}
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  const GoogleFormContent = () => {
    return (
      <Box className={classes.seminarFormWrapper}>
        <Box className={classes.seminarFormContentWrapper}>
          <Box className={classes.seminarContentBox}>
            <Box className={classes.formSpacing}>
              <h4 className={clsx(fontClasses.h4, classes.titleContent)}>
                {t('seminarsPage.enrollments')}
              </h4>
              <p className={clsx(fontClasses.p)}>{t('seminarsPage.enrollmentsText')}</p>
            </Box>
            <iframe
              sandbox
              title='Survey'
              src={seminar.attributes.form}
              frameBorder='0'
              marginHeight='0'
              marginWidth='0'
              className={classes.googleFormStyle}
            >
              <CircularProgress />
            </iframe>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Metatags
        title='CEMCA | Capacitaciones'
        description='Descubre nuestro catálogo de capacitaciones diseñadas para impulsar tu crecimiento profesional.'
      />
      {seminar && (
        <>
          <SeminarPageHeader backTo={t('navbar.courses')} seminar={seminar} />
          <SeminarContent />
        </>
      )}
      {loading && (
        <Box
          sx={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default SeminarPage;
