import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS, DIMENSIONS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles((theme) => ({
  projectWrapper: {
    backgroundColor: COLORS.ligthCyan,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  projectWrapperWhite: {
    backgroundColor: COLORS.white,
  },
  projectContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginTop: '100px',
    marginBottom: '100px',
    display: 'flex',
    gap: 25,
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
      flexWrap: 'wrap',
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginTop: '80px',
      marginBottom: '80px',
    },
  },
  carrouselBox: {
    width: '50%',
    [themeBreakpoints.down('md')]: {
      width: '100%',
    },
  },
  contentBox: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    [themeBreakpoints.down('md')]: {
      width: '100%',
      display: 'block',
    },
  },
  leftArrowBox: {
    position: 'absolute',
    height: '100%',
    left: 0,
    zIndex: 90,
    marginLeft: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  rightArrowBox: {
    position: 'absolute',
    height: '100%',
    top: 0,
    right: 0,
    marginRight: '5px',
    zIndex: 90,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  arrows: {
    fontSize: '50px',
    color: 'rgba(255, 255, 255, 0.5)',
    cursor: 'pointer',
    transform: 'scale(2)',
    margin: 0,
    '&:hover': {
      color: COLORS.white,
    },
  },
  indicatorsBox: {
    display: 'flex',
    gap: 50,
    marginTop: '25px',
    alignItems: 'flex-end',
    [themeBreakpoints.down('md')]: {
      flexWrap: 'wrap',
      gap: 30,
    },
  },
  summaryBox: {
    marginTop: '10px',
  },
  zonesBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: 50,
    marginTop: '10px',
    flexWrap: 'wrap',
    [themeBreakpoints.down('sm')]: {
      gap: 25,
    },
  },
  indicatorsContent: {
    color: COLORS.cyan,
    margin: 0,
  },
  indicatorsTitle: {
    width: '100%',
    fontWeight: 400,
    fontSize: '13px',
  },
  // revisar
  imageBox: {
    width: '100%',
    height: '500px',
  },
  projectImg: {
    width: '100%',
    height: '500px',
    objectFit: 'cover',
    borderRadius: '10px',
    [themeBreakpoints.down('sm')]: {
      height: '90%',
    },
  },
  ligthText: {
    fontWeight: 300,
  },

  communitiesLabel: {
    fontWeight: 500,
  },
}));

export default useStyles;
