import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles((theme) => ({
  seminarInfoBox: {
    width: '100%',
    backgroundColor: 'white',
    position: 'relative',
    borderRadius: '10px',
    boxShadow: '0px 5px 20px 5px rgba(159, 159, 159, 0.20)',
    [themeBreakpoints.down('sm')]: {
      marginTop: '10px',
    },
  },
  seminarInfoContentBox: {
    padding: '30px',
  },
  seminarImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderTopRightRadius: '10px',
    borderTopLeftRadius: '10px',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  informationTitle: {
    marginBottom: '25px',
  },
  iconTitle: {
    color: COLORS.cyan,
  },
  infoItemBox: {
    display: 'flex',
    gap: 10,
    marginBottom: '15px',
  },
  infoItem2Box: {
    display: 'flex',
    gap: 10,
  },
  informationTitle2: {
    marginBottom: '25px',
    marginTop: '25px',
  },
  boldLabel: {
    fontWeight: 500,
  },
}));

export default useStyles;
