import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

import { COLORS } from '../constants';
import breakpoints from './breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const components = {
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: COLORS.gray,
          borderRadius: '10px',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: COLORS.cyan,
        },
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      root: {
        borderWidth: 1,
        backgroundColor: COLORS.white,
        '&.Mui-selected': {
          backgroundColor: `${COLORS.cyan} !important`,
          color: `${COLORS.gray} !important`,
        },
      },
      today: {
        color: COLORS.gray,
        backgroundColor: `${COLORS.white} !important`,
        borderColor: `${COLORS.cyan} !important`,
      },
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      yearButton: {
        borderWidth: 1,
        backgroundColor: COLORS.white,
        '&.Mui-selected': {
          backgroundColor: `${COLORS.cyan} !important`,
          color: `${COLORS.gray} !important`,
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      standardInfo: {
        backgroundColor: `${COLORS.ligthCyan} !important`,
        color: `${COLORS.gray} !important`,
        '& .MuiAlert-icon	': {
          color: `${COLORS.cyan} !important`,
        },
      },
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: `${COLORS.cyan} !important`,
          color: `${COLORS.white} !important`,
        },
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: '10px',
        [themeBreakpoints.down('sm')]: {
          borderRadius: '0px',
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        backgroundColor: `${COLORS.cyan} !important`,
        color: `${COLORS.white} !important`,
        fontFamily: ['Poppins'].join(','),
      },
    },
  },
};

export default components;
