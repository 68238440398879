import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles((theme) => ({
  memberBox: {
    width: '350px',
    minHeight: '450px',
    height: '100%',
    backgroundColor: COLORS.white,
    borderRadius: '10px',
    boxShadow: '0px 0px 20px 5px rgba(159, 159, 159, 0.20)',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    boxSizing: 'border-box',
    '&:hover': {
      transform: 'scale(1.05)',
      opacity: '0.9',
    },
    [themeBreakpoints.down('md')]: {
      width: '390px',
    },
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  coverImgBox: {
    width: '100%',
    [themeBreakpoints.down('md')]: {
      // width: '40%',
    },
  },
  coverImg: {
    width: '100%',
    height: '230px',
    objectFit: 'cover',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
  logoImgBox: {
    width: '40%',
    [themeBreakpoints.down('md')]: {
      // width: '40%',
    },
  },
  logoImg: {
    width: '100%',
    height: '80px',
    objectFit: 'contain',
    objectPosition: 'left center',
  },
  memberInformartion: {
    boxSizing: 'border-box',
    padding: '20px',
    [themeBreakpoints.down('sm')]: {
      padding: '15px',
      width: '100%',
    },
  },
  memberTitle: {
    fontWeight: 600,
    marginBottom: '10px',
  },
  memberText: {
    fontSize: '14px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
}));

export default useStyles;
