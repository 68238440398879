import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import Box from '@mui/material/Box';
import clsx from 'clsx';
import React from 'react';

import { COLORS } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import useStyles from './styles';

const CustomFunctionButton = ({ label, handlerFun, type }) => {
  const fontClasses = fontsStyles();
  const classes = useStyles();
  let boxClass;
  let arrowColor;
  if (type === 'secondary') {
    boxClass = classes.buttonSecondaryBox;
    arrowColor = COLORS.cyan;
  } else if (type === 'banner') {
    boxClass = classes.buttonBannerBox;
    arrowColor = COLORS.gray;
  } else {
    boxClass = classes.buttonPrimaryBox;
    arrowColor = COLORS.white;
  }

  return (
    <button className={clsx(boxClass, classes.linkStyle)} onClick={handlerFun} type='submit'>
      <p className={fontClasses.primaryButtonText} style={{ color: arrowColor }}>
        {label}
      </p>
      <ArrowForwardIcon style={{ color: arrowColor }} />
    </button>
  );
};

export default CustomFunctionButton;
