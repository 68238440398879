import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS, DIMENSIONS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  seminarsWrapper: {
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  seminarsContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginTop: '100px',
    marginBottom: '150px',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginTop: '80px',
      marginBottom: '80px',
    },
  },
  seminarsListWrapper: {
    backgroundColor: COLORS.ligthCyan,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
      marginTop: '50px',
    },
  },
  seminarsListContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginBottom: '80px',
    },
  },
  filtersWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    backgroundColor: COLORS.white,
    borderRadius: '10px',
    position: 'relative',
    padding: '30px',
    top: -55,
    boxShadow: ' 0px 0px 20px 0px rgba(159, 159, 159, 0.20)',
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      gap: 10,
    },
  },
  searchBox: {
    width: '50%',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  filtersBox: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-end',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'start',
    },
  },
  sectionTitle: {
    width: '750px',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  sectionText: {
    width: '650px',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'justify',
    },
  },
  buttonsBox: {
    marginTop: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 15,
  },
  notFoundContent: {
    width: '100%',
    height: '400px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  errorBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 20,
    marginBottom: '50px',
  },
  errorBoxInfo: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 5,
  },
  titleLabel: {
    marginBottom: '25px',
    width: '75%',
    textAlign: 'center',
    [themeBreakpoints.down('sm')]: {
      margin: '20px 0',
      textAlign: 'center',
      width: '100%',
    },
  },
  descriptionLabel: {
    marginBottom: '25px',
    width: '75%',
    textAlign: 'center',
    [themeBreakpoints.down('sm')]: {
      textAlign: 'justify',
      width: '100%',
    },
  },
  seminarBox: {
    marginBottom: '50px',
  },
}));

export default styles;
