import EmailIcon from '@mui/icons-material/Email';
import EventIcon from '@mui/icons-material/Event';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PaymentsIcon from '@mui/icons-material/Payments';
import PlaceIcon from '@mui/icons-material/Place';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

import fontsStyles from '../../styles/fontStyles';
import useStyles from './styles';

const Seminar = ({
  ID,
  title,
  img,
  tag,
  summary,
  startDate,
  modality,
  price,
  duration,
  phone,
  mail,
}) => {
  const fontClasses = fontsStyles();
  const classes = useStyles();

  return (
    <Link to={`/capacitaciones/${ID}`} className={classes.linkBox}>
      <Box className={classes.seminarBox}>
        <Box
          className={classes.seminarImgBox}
          sx={{
            display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' },
          }}
        >
          <img src={img} className={classes.seminarImg} />
        </Box>
        <Box className={classes.semminarInformartion}>
          <h4 className={clsx(fontClasses.h4, classes.seminarTitle)}>{title}</h4>
          <p className={clsx(fontClasses.p, classes.labelTag)}>{tag}</p>
          <Box
            sx={{
              display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' },
              marginBottom: '25px',
            }}
          >
            <img src={img} className={classes.seminarImg} />
          </Box>
          <p className={clsx(fontClasses.p)}>{summary}</p>
          <Box className={classes.summaryBox}>
            <Box className={classes.summaryColumn}>
              <Box className={classes.infoItemBox}>
                <p className={clsx(fontClasses.p, classes.iconTitle)}>
                  <PlaceIcon style={{ fontSize: '25px' }} />
                </p>
                <p className={clsx(fontClasses.p)}>{modality}</p>
              </Box>
              <Box className={classes.infoItemBox}>
                <p className={clsx(fontClasses.p, classes.iconTitle)}>
                  <EventIcon style={{ fontSize: '25px' }} />
                </p>
                <Box>
                  {startDate.map((item, index) => (
                    <p key={index} className={clsx(fontClasses.p)}>
                      {item}
                    </p>
                  ))}
                </Box>
              </Box>
            </Box>
            <Box className={classes.summaryColumn}>
              <Box className={classes.infoItemBox}>
                <p className={clsx(fontClasses.p, classes.iconTitle)}>
                  <EmailIcon style={{ fontSize: '25px' }} />
                </p>
                <Box>
                  {mail.map((item, index) => (
                    <p key={index} className={clsx(fontClasses.p)}>
                      {item}
                    </p>
                  ))}
                </Box>
              </Box>
              <Box className={classes.infoItemBox}>
                <p className={clsx(fontClasses.p, classes.iconTitle)}>
                  <TimelapseIcon style={{ fontSize: '25px' }} />
                </p>
                <p className={clsx(fontClasses.p)}>{duration}</p>
              </Box>
            </Box>
            <Box className={classes.summaryColumn}>
              <Box className={classes.infoItemBox}>
                <p className={clsx(fontClasses.p, classes.iconTitle)}>
                  <PaymentsIcon style={{ fontSize: '25px' }} />
                </p>
                <Box>
                  {price.map((item, index) => (
                    <p key={index} className={clsx(fontClasses.p)}>
                      {item}
                    </p>
                  ))}
                </Box>
              </Box>
              <Box className={classes.infoItemBox}>
                <p className={clsx(fontClasses.p, classes.iconTitle)}>
                  <LocalPhoneIcon style={{ fontSize: '25px' }} />
                </p>
                <Box>
                  {phone.map((item, index) => (
                    <p key={index} className={clsx(fontClasses.p)}>
                      {item}
                    </p>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export default Seminar;
