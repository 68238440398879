import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Logo from '../../assets/img/logo-white.png';
import { PATHS } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import useStyles from './styles';

export default function Footer(props) {
  const classes = useStyles();
  const fontClasses = fontsStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.footerWrapper}>
      <Box className={classes.footerContent}>
        <Grid container rowSpacing={{ xs: 4, md: 4 }}>
          <Grid item sm={12} md={12} lg={4}>
            <Box className={classes.footerLogoBox}>
              <Link to='/'>
                <img src={Logo} alt='Cemca logo' className={classes.footerLogo} />
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={8}>
            <Box className={classes.columnsFooter}>
              <Box className={classes.columnFooter}>
                <h4 className={fontClasses.footercolumnTitle}>{t('footer.about')}</h4>
                <Box className={classes.footerItemBox}>
                  <Link to={PATHS.history} className={fontClasses.footercolumnText}>
                    {t('footer.history')}
                  </Link>
                  <Link to={PATHS.projects} className={fontClasses.footercolumnText}>
                    {t('footer.projects')}
                  </Link>
                </Box>
              </Box>
              <Box className={classes.columnFooter}>
                <h4 className={fontClasses.footercolumnTitle}>{t('footer.links')}</h4>
                <Box className={classes.footerItemBox}>
                  <Link to={PATHS.affiliation} className={fontClasses.footercolumnText}>
                    {t('footer.affiliation')}
                  </Link>
                  <Link to={PATHS.directory} className={fontClasses.footercolumnText}>
                    {t('footer.affiliationList')}
                  </Link>
                  <Link to={PATHS.courses} className={fontClasses.footercolumnText}>
                    {t('footer.courses')}
                  </Link>
                  <Link to={PATHS.coworking} className={fontClasses.footercolumnText}>
                    {t('footer.coworking')}
                  </Link>
                </Box>
              </Box>
              <Box className={classes.columnFooter}>
                <h4 className={fontClasses.footercolumnTitle}>{t('footer.conect')}</h4>
                <Box className={classes.footerItemBox}>
                  <a
                    href={
                      'https://www.google.com/maps/place/Lim%C3%B3n/@9.9918421,-83.0602886,14z/data=!3m1!4b1!4m5!3m4!1s0x8fa705367150d903:0x24d864fd050c815f!8m2!3d9.9913106!4d-83.0415078'
                    }
                    className={fontClasses.footercolumnText}
                  >
                    <LocationOnIcon />
                    Entrada a Barrio Veracruz, Limón, CR.
                  </a>
                  <a href={'tel:+50627582169'} className={fontClasses.footercolumnText}>
                    <LocalPhoneIcon />
                    +506 27582169
                  </a>
                  <a
                    href={'mailto: cemcalimoncr@gmail.com'}
                    className={fontClasses.footercolumnText}
                  >
                    <EmailIcon />
                    cemcalimoncr@gmail.com
                  </a>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <hr className={classes.divisorLine} />
        <Box className={classes.columnsRights}>
          <p className={fontClasses.smallText}>
            {new Date().getFullYear()} {t('footer.rights')}
          </p>
          <Box className={classes.footerSocialBox}>
            <a
              href='https://www.facebook.com/profile.php?id=100056245969007'
              target='_blank'
              rel='noreferrer'
            >
              <p className={classes.socialIcon}>
                <FacebookIcon style={{ fontSize: '25px' }} />
              </p>
            </a>
            <a href='https://www.instagram.com/cemcacr' target='_blank' rel='noreferrer'>
              <p className={classes.socialIcon}>
                <InstagramIcon style={{ fontSize: '25px' }} />
              </p>
            </a>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
