import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS, DIMENSIONS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);
const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    backgroundColor: COLORS.ligthCyan,
    height: '350px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  headerContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    display: 'flex',
    alignItems: 'center',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      paddingTop: '50px',
      paddingBottom: '50px',
    },
  },
  routeTextLink: {
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    marginBottom: '15px',
    '&:hover': {
      color: COLORS.cyan,
    },
  },
  routeTile: {
    width: '90%',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  pathLink: {
    textDecoration: 'none',
    '&:hover': {
      color: COLORS.cyan,
    },
  },
}));

export default useStyles;
