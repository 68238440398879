import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { DIMENSIONS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  contentWrapper: {
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  secondContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginTop: '150px',
    marginBottom: '150px',
    alignItems: 'center',
    justifyContent: 'center',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
      marginTop: '100px',
      marginBottom: '100px',
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginTop: '50px',
      marginBottom: '50px',
    },
  },
  contentHeaderContent: {
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      marginBottom: '50px',
    },
  },
  buttonsBox: {
    marginTop: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 15,
  },
  pMessage: {
    textAlign: 'center',
  },
}));

export default styles;
