import Box from '@mui/material/Box';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getWeatherData } from '../../api/weather';
import fontsStyles from '../../styles/fontStyles';
import useStyles from './styles';

const SubNavbar = () => {
  const classes = useStyles();
  const fontClasses = fontsStyles();
  const [weatherData, setWeatherData] = useState();
  const [language, setLanguage] = useState('es');
  const { t, i18n } = useTranslation();

  const changeLanguage = () => {
    if (language === 'es') setLanguage('en');
    else setLanguage('es');
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      getWeatherData()
        .then(function (response) {
          setWeatherData(response.data);
        })
        .catch(() => {
          console.log('error obteniendo el clima');
        });
    }
  }, []);

  return (
    <Box className={classes.navbarWrapper}>
      <Box className={classes.navbarContent}>
        {weatherData && (
          <Box className={classes.wheatherBox}>
            <img
              src={`https://openweathermap.org/img/wn/${weatherData.weather[0].icon}@2x.png`}
              alt={weatherData.weather[0].main}
              width='30'
              height='30'
            />
            <p className={fontClasses.smallText}>{Math.round(weatherData.main.temp)}°</p>
            <p className={fontClasses.smallText}>{weatherData.name}</p>
          </Box>
        )}
        <p className={fontClasses.smallText}>|</p>
        <button
          className={clsx(fontClasses.smallText, classes.languageButton)}
          onClick={changeLanguage}
        >
          {t('subNavbar.languageSelector')}
        </button>
      </Box>
    </Box>
  );
};

export default SubNavbar;
