import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS, DIMENSIONS } from '../../constants/';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles((theme) => ({
  footerWrapper: {
    backgroundColor: COLORS.cyan,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  footerContent: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginTop: '120px',
    marginBottom: '100px',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginTop: '100px',
      marginBottom: '50px',
    },
  },
  footerItemBox: {
    marginTop: '25px',
    display: 'flex',
    flexDirection: 'column',
    [themeBreakpoints.down('md')]: {
      marginTop: '20px',
    },
    [themeBreakpoints.down('sm')]: {
      marginTop: '15px',
    },
  },
  columnsFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    [themeBreakpoints.down('sm')]: {
      display: 'block',
    },
  },
  columnFooter: {
    [themeBreakpoints.down('md')]: {
      marginBottom: '40px',
    },
  },
  columnsRights: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [themeBreakpoints.down('sm')]: {
      display: 'block',
    },
  },
  divisorLine: {
    backgroundColor: COLORS.white,
    height: '1px',
    border: 'none',
    marginTop: '80px',
    [themeBreakpoints.down('md')]: {
      marginTop: '0px',
      marginBottom: '10px',
    },
  },
  footerLogo: {
    maxWidth: '150px',
  },
  // revisar

  footerRights: {
    width: '100%',
    textAlign: 'right',
    fontSize: 13,
    fontWeight: 400,
    color: '#151B25',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
      textAlign: 'center',
    },
  },
  footerSocialBox: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    gap: '20px',
  },
  socialIcon: {
    color: COLORS.white,
    '&:hover': {
      textDecoration: 'none',
      color: COLORS.yellow,
    },
  },
  newsletterMessage: {
    marginTop: '20px',
    width: '100%',
    textAlign: 'justify',
    fontSize: 13,
    fontWeight: 300,
    color: '#151B25',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  buttonIcon: {
    fontSize: '20px!important',
    color: '#0077FF',
    [themeBreakpoints.down('sm')]: {
      fontSize: '16px!important',
    },
  },
}));

export default useStyles;
