import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../../constants/';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles((theme) => ({
  linkBox: {
    textDecoration: 'none',
  },
  seminarBox: {
    width: '100%',
    backgroundColor: COLORS.white,
    borderRadius: '10px',
    display: 'flex',
    boxShadow: '0px 0px 20px 5px rgba(159, 159, 159, 0.20)',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
      opacity: '0.9',
    },
  },
  seminarImgBox: {
    width: '30%',
    [themeBreakpoints.down('md')]: {
      width: '40%',
    },
  },
  seminarImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    [themeBreakpoints.down('sm')]: {
      borderRadius: '10px',
    },
  },
  semminarInformartion: {
    width: '70%',
    boxSizing: 'border-box',
    padding: '30px',
    [themeBreakpoints.down('md')]: {
      width: '60%',
    },
    [themeBreakpoints.down('sm')]: {
      padding: '15px',
      width: '100%',
    },
  },
  seminarTitle: {
    marginBottom: '10px',
  },
  labelTag: {
    color: COLORS.cyan,
    fontWeight: 600,
    marginBottom: '25px',
  },
  summaryBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  summaryColumn: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  infoItemBox: {
    width: '50%',
    display: 'flex',
    alignItems: 'start',
    gap: 10,
    [themeBreakpoints.down('md')]: {
      width: '100%',
    },
  },
  iconTitle: {
    color: COLORS.cyan,
  },
}));

export default useStyles;
