/* eslint-disable react/jsx-no-undef */
import { gql, useQuery } from '@apollo/client';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Skeleton } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import CustomButton from '../../components/CustomButton';
import Metatags from '../../components/MetaTags/metatags';
import ProjectCard from '../../components/ProjectCard';
import { COLORS, PATHS } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

const PROJECTS_QUERY = gql`
  query ($language: I18NLocaleCode!) {
    projects(sort: "order", locale: $language) {
      data {
        id
        attributes {
          title
          tag
          summary
          zones
          beneficiaries
          communityTotal
          editions
          ideas
          months
          inProgress
          images {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
      meta {
        pagination {
          page
          pageCount
          pageSize
          total
        }
      }
    }
  }
`;

const Projects = () => {
  const classes = styles();
  const fontClasses = fontsStyles();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const Header = () => {
    return (
      <Box className={classes.projectWrapper}>
        <Box className={classes.projectContentWrapper}>
          <Box>
            <p className={fontClasses.sectionSubTitles}>{t('projectsPage.headerSubTitle')}</p>
            <h1 className={clsx(fontClasses.h1, classes.sectionTitle)}>
              {t('projectsPage.headerTitle')}
            </h1>
            <p className={clsx(fontClasses.p, classes.sectionText)}>
              {t('projectsPage.headerText')}
            </p>
            <Box className={classes.buttonsBox}>
              <CustomButton label={t('projectsPage.contact')} url={PATHS.contact} />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const ProjectsContent = () => {
    const {
      data: projectsData,
      loading,
      error,
    } = useQuery(PROJECTS_QUERY, {
      variables: {
        language: i18n.language,
      },
    });

    return (
      <Box>
        {loading && !error && (
          <Box className={classes.loadingWrapper}>
            <Box className={classes.loadingContentWrapper}>
              <Box className={classes.loadingBox}>
                <Box className={classes.loadingContentBox}>
                  <Skeleton
                    variant='rectangular'
                    height={100}
                    sx={{
                      marginBottom: '20px',
                      borderRadius: '10px',
                      width: '100%',
                    }}
                  />
                  <Skeleton
                    variant='rectangular'
                    height={20}
                    sx={{
                      marginBottom: '20px',
                      borderRadius: '10px',
                      width: '100%',
                    }}
                  />
                  <Skeleton
                    variant='rectangular'
                    height={250}
                    sx={{
                      marginBottom: '20px',
                      borderRadius: '10px',
                      width: '100%',
                    }}
                  />
                  <Skeleton
                    variant='rectangular'
                    height={70}
                    sx={{
                      marginBottom: '20px',
                      borderRadius: '10px',
                      width: '100%',
                    }}
                  />
                </Box>
                <Box className={classes.loadingImageBox}>
                  <Skeleton
                    variant='rectangular'
                    height={500}
                    sx={{
                      borderRadius: '10px',
                      width: '100%',
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {error && (
          <Box className={classes.loadingWrapper}>
            <Box className={classes.loadingContentWrapper}>
              <Box className={classes.errorBox}>
                <InfoOutlinedIcon sx={{ color: COLORS.gray, fontSize: '100px' }} />
                <Box className={classes.errorBoxInfo}>
                  <h4 className={fontClasses.h4}>{t('validationsForm.errorTitle')}</h4>
                  <p className={fontClasses.p}>{t('validationsForm.errorMessage')}</p>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {projectsData &&
          !loading &&
          !error &&
          projectsData.projects.data.map((project, index) => {
            return (
              <Box key={project.id}>
                <ProjectCard
                  index={index}
                  title={project.attributes.title}
                  summary={project.attributes.summary}
                  images={project.attributes.images.data}
                  ideas={project.attributes.ideas}
                  beneficiaries={project.attributes.beneficiaries}
                  months={project.attributes.months}
                  editions={project.attributes.editions}
                  communityTotal={project.attributes.communityTotal}
                  zones={project.attributes.zones}
                  tag={project.attributes.tag}
                  inProgress={project.attributes.inProgress}
                />
              </Box>
            );
          })}
      </Box>
    );
  };

  return (
    <Box>
      <Metatags
        title='CEMCA | Proyectos'
        description='Te invitamos a explorar una selección de proyectos emblemáticos en los que hemos trabajado.'
      />
      <Header />
      <ProjectsContent />
    </Box>
  );
};

export default Projects;
