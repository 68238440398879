import EmailIcon from '@mui/icons-material/Email';
import EventIcon from '@mui/icons-material/Event';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PaymentsIcon from '@mui/icons-material/Payments';
import PlaceIcon from '@mui/icons-material/Place';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import fontsStyles from '../../styles/fontStyles';
import useStyles from './styles';

const SeminarInformation = ({
  seminarImg,
  startDate,
  modality,
  price,
  duration,
  phone,
  mail,
  paymentBank,
  paymentAccount,
  minimumCapacity,
  enrollmentStart,
  enrollmentEnd,
}) => {
  const fontClasses = fontsStyles();
  const classes = useStyles();
  const { i18n, t } = useTranslation();

  return (
    <Box className={classes.seminarInfoBox}>
      <img src={seminarImg} className={classes.seminarImg} />
      <Box className={classes.seminarInfoContentBox}>
        <h4 className={clsx(fontClasses.h4, classes.informationTitle)}>
          {t('seminarsPage.information')}
        </h4>
        <Box className={classes.infoItemBox}>
          <p className={clsx(fontClasses.p, classes.iconTitle)}>
            <EventIcon style={{ fontSize: '25px' }} />
          </p>
          <Box>
            {startDate.map((item, index) => (
              <p key={index} className={clsx(fontClasses.p, fontClasses.noWrap)}>
                {item}
              </p>
            ))}
          </Box>
        </Box>
        <Box className={classes.infoItemBox}>
          <p className={clsx(fontClasses.p, classes.iconTitle)}>
            <PlaceIcon style={{ fontSize: '25px' }} />
          </p>
          <p className={clsx(fontClasses.p)}>{modality}</p>
        </Box>
        <Box className={classes.infoItemBox}>
          <p className={clsx(fontClasses.p, classes.iconTitle)}>
            <PaymentsIcon style={{ fontSize: '25px' }} />
          </p>
          <Box>
            {price.map((item, index) => (
              <p key={index} className={clsx(fontClasses.p)}>
                {item}
              </p>
            ))}
          </Box>
        </Box>
        <Box className={classes.infoItemBox}>
          <p className={clsx(fontClasses.p, classes.iconTitle)}>
            <TimelapseIcon style={{ fontSize: '25px' }} />
          </p>
          <p className={clsx(fontClasses.p)}>{duration}</p>
        </Box>
        <Box className={classes.infoItemBox}>
          <p className={clsx(fontClasses.p, classes.iconTitle)}>
            <LocalPhoneIcon style={{ fontSize: '25px' }} />
          </p>
          <Box>
            {phone.map((item, index) => (
              <p key={index} className={clsx(fontClasses.p, fontClasses.noWrap)}>
                {item}
              </p>
            ))}
          </Box>
        </Box>
        <Box className={classes.infoItemBox}>
          <p className={clsx(fontClasses.p, classes.iconTitle)}>
            <EmailIcon style={{ fontSize: '25px' }} />
          </p>
          <Box>
            {mail.map((item, index) => (
              <p key={index} className={clsx(fontClasses.p, fontClasses.noWrap)}>
                {item}
              </p>
            ))}
          </Box>
        </Box>
        {paymentBank && paymentAccount && (
          <Box>
            <h4 className={clsx(fontClasses.h4, classes.informationTitle2)}>Información de pago</h4>
            <Box className={classes.infoItem2Box}>
              <p className={clsx(fontClasses.p, classes.iconTitle)}>
                <PaymentsIcon style={{ fontSize: '25px' }} />
              </p>
              <Box>
                <p className={clsx(fontClasses.p, classes.boldLabel)}>
                  <b>{paymentBank}</b>
                </p>
                <p className={clsx(fontClasses.p)}>{paymentAccount}</p>
              </Box>
            </Box>
          </Box>
        )}
        <Box>
          <p className={clsx(fontClasses.p)}>
            <b>{t('seminarsPage.startEnrollment')}: </b>
            {dayjs(enrollmentStart).locale(i18n.language).format('LL')}
          </p>
          <p className={clsx(fontClasses.p)}>
            <b>{t('seminarsPage.endEnrollment')}: </b>
            {dayjs(enrollmentEnd).locale(i18n.language).format('LL')}
          </p>
          <p className={clsx(fontClasses.p)}>
            <b>{t('seminarsPage.minimumCapacity')}: </b>
            {minimumCapacity}{' '}
          </p>
        </Box>
      </Box>
    </Box>
  );
};

export default SeminarInformation;
