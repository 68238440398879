import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

import breakpoints from './breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const typography = {
  h1: {
    fontSize: 40,
    fontWeight: 700,
    [themeBreakpoints.down('md')]: {
      fontSize: 20,
    },
    fontFamily: ['Poppins'].join(','),
  },
  h2: {
    fontSize: 30,
    fontWeight: 700,
    [themeBreakpoints.down('md')]: {
      fontSize: 18,
    },
    fontFamily: ['Poppins'].join(','),
  },
  h3: {
    fontSize: 20,
    fontWeight: 600,
    [themeBreakpoints.down('md')]: {
      fontSize: 15,
    },
    fontFamily: ['Poppins'].join(','),
  },
  body1: {
    fontSize: 18,
    fontWeight: 400,
    fontFamily: ['Poppins'].join(','),
  },
  body2: {
    fontSize: 18,
    fontWeight: 400,
    fontFamily: ['Poppins'].join(','),
  },
};

export default typography;
