import { gql, useQuery } from '@apollo/client';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Box, Skeleton } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import CoursesBanner from '../../components/CoursesBanner';
import CustomButton from '../../components/CustomButton';
import Metatags from '../../components/MetaTags/metatags';
import TeamMember from '../../components/TeamMember';
import { COLORS, PATHS } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

const TEAM_QUERY = gql`
  query ($language: I18NLocaleCode!) {
    teamMembers(sort: "order", locale: $language) {
      data {
        attributes {
          name
          position
          business
          experience
          photo {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
    }
  }
`;

const History = () => {
  const classes = styles();
  const fontClasses = fontsStyles();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const Header = () => {
    return (
      <Box className={classes.historyWrapper}>
        <Box className={classes.historyContentWrapper}>
          <Box>
            <p className={fontClasses.sectionSubTitles}>{t('historyPage.headerSubTitle')}</p>
            <h1 className={clsx(fontClasses.h1, classes.sectionTitle)}>
              {t('historyPage.headerTitle')}
            </h1>
            <p className={clsx(fontClasses.p, classes.sectionText)}>
              {t('historyPage.headerText')}
            </p>
            <Box className={classes.buttonsBox}>
              <CustomButton label={t('historyPage.contactButton')} url={PATHS.contact} />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const Values = () => {
    return (
      <Box className={classes.historyWrapper}>
        <Box className={classes.misionContentWrapper}>
          <Box className={classes.valuesBox}>
            <Box className={classes.valuesItem}>
              <Box className={classes.valuesIcon}>
                <EmojiFlagsIcon sx={{ color: COLORS.cyan, fontSize: '35px' }} />
              </Box>
              <h5 className={fontClasses.h5}>{t('historyPage.misionTitle')}</h5>
              <p className={clsx(fontClasses.p, fontClasses.justify)}>
                {t('historyPage.misionText')}
              </p>
            </Box>
            <Box className={classes.valuesItem}>
              <Box className={classes.valuesIcon}>
                <RemoveRedEyeOutlinedIcon sx={{ color: COLORS.cyan, fontSize: '35px' }} />
              </Box>
              <h5 className={fontClasses.h5}>{t('historyPage.visionTitle')}</h5>
              <p className={clsx(fontClasses.p, fontClasses.justify)}>
                {t('historyPage.visionText')}
              </p>
            </Box>
            {/* 
            <Box className={classes.valuesItem}>
              <Box className={classes.valuesIcon}>
                <ExtensionOutlinedIcon sx={{ color: COLORS.cyan, fontSize: '35px' }} />
              </Box>
              <h5 className={fontClasses.h5}>{t('historyPage.valuesTitle')}</h5>
              <p className={clsx(fontClasses.p, fontClasses.justify)}>
                {t('historyPage.valuesText')}
              </p>
            </Box>
            */}
          </Box>
        </Box>
      </Box>
    );
  };

  const OurWork = () => {
    return (
      <Box className={classes.historyWrapper}>
        <Box className={classes.misionContentWrapper}>
          <Box className={classes.centerSection}>
            <p className={fontClasses.sectionSubTitles}>{t('historyPage.workSubtitle')}</p>
            <h1 className={clsx(fontClasses.h2, classes.sectionTitleCenter)}>
              {t('historyPage.workTitle')}
            </h1>
          </Box>
          <Box className={classes.workItemsBox}>
            <Box className={classes.workItem}>
              <Box className={classes.workItemContent}>
                <h5 className={fontClasses.h5}>{t('historyPage.workTitleItem1')}</h5>
                <p className={fontClasses.p}>
                  {t('historyPage.workTextItem1')} <br />{' '}
                  <Link className={fontClasses.linkText} to={PATHS.directory}>
                    {t('historyPage.workLinkItem1')}
                  </Link>
                </p>
              </Box>
              <Box className={classes.workItemImgBox}>
                <img
                  className={classes.workItemImg}
                  src={
                    'https://res.cloudinary.com/sealabs/image/upload/v1704039472/cemca.co.cr/website/mercado_wavlsi.webp'
                  }
                />
              </Box>
            </Box>
            <Box className={classes.workItem}>
              <Box
                className={classes.workItemImgBox}
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'none',
                    lg: 'flex',
                    xl: 'flex',
                  },
                }}
              >
                <img
                  className={classes.workItemImg}
                  src={
                    'https://res.cloudinary.com/sealabs/image/upload/v1704039470/cemca.co.cr/website/incubadora_dltima.jpg'
                  }
                />
              </Box>
              <Box className={classes.workItemContent}>
                <h5 className={fontClasses.h5}>{t('historyPage.workTitleItem2')}</h5>
                <p className={fontClasses.p}>
                  {t('historyPage.workTextItem2')} <br />{' '}
                  <Link className={fontClasses.linkText} to={PATHS.projects}>
                    {t('historyPage.workLinkItem2')}
                  </Link>
                </p>
              </Box>
              <Box
                className={classes.workItemImgBox}
                sx={{
                  display: {
                    xs: 'flex',
                    sm: 'flex',
                    md: 'flex',
                    lg: 'none',
                    xl: 'none',
                  },
                }}
              >
                <img
                  className={classes.workItemImg}
                  src={
                    'https://res.cloudinary.com/sealabs/image/upload/v1704039470/cemca.co.cr/website/incubadora_dltima.jpg'
                  }
                />
              </Box>
            </Box>
            <Box className={classes.workItem}>
              <Box className={classes.workItemContent}>
                <h5 className={fontClasses.h5}>{t('historyPage.workTitleItem3')}</h5>
                <p className={fontClasses.p}>
                  {t('historyPage.workTextItem3')} <br />{' '}
                  <Link className={fontClasses.linkText} to={PATHS.coworking}>
                    {t('historyPage.workLinkItem3')}
                  </Link>
                </p>
              </Box>
              <Box className={classes.workItemImgBox}>
                <img
                  className={classes.workItemImg}
                  src={
                    'https://res.cloudinary.com/sealabs/image/upload/v1704039471/cemca.co.cr/website/oficina_jkgzn6.webp'
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const Team = () => {
    const {
      data: teamData,
      loading,
      error,
    } = useQuery(TEAM_QUERY, {
      variables: {
        language: i18n.language,
      },
    });

    return (
      <Box className={classes.historyWrapper}>
        <Box className={classes.historyContentWrapper}>
          <Box>
            <p className={fontClasses.sectionSubTitles}>{t('historyPage.teamSubtitle')}</p>
            <h1 className={clsx(fontClasses.h2, classes.partnerTitle)}>
              {t('historyPage.teamTitle')}
            </h1>
            <p className={clsx(fontClasses.p, classes.sectionText)}>
              {t('historyPage.teamText')}{' '}
              <Link className={fontClasses.linkText} to={PATHS.contact}>
                {t('historyPage.contactButton')}
              </Link>
            </p>
          </Box>
          <Box className={classes.teamMembersBox}>
            {loading && (
              <>
                <Skeleton
                  variant='rectangular'
                  height={500}
                  sx={{ borderRadius: '10px', width: { xs: '100%', sm: '100%', md: 350, lg: 350 } }}
                />
                <Skeleton
                  variant='rectangular'
                  height={500}
                  sx={{ borderRadius: '10px', width: { xs: '100%', sm: '100%', md: 350, lg: 350 } }}
                />
                <Skeleton
                  variant='rectangular'
                  height={500}
                  sx={{ borderRadius: '10px', width: { xs: '100%', sm: '100%', md: 350, lg: 350 } }}
                />
              </>
            )}
            {teamData &&
              !error &&
              teamData.teamMembers.data.map((member, index) => {
                return (
                  <Box key={index}>
                    <TeamMember member={member} />
                  </Box>
                );
              })}
          </Box>
          {error && (
            <Box className={classes.errorBox}>
              <InfoOutlinedIcon sx={{ color: COLORS.gray, fontSize: '100px' }} />
              <Box className={classes.errorBoxInfo}>
                <h4 className={fontClasses.h4}>{t('validationsForm.errorTitle')}</h4>
                <p className={fontClasses.p}>{t('validationsForm.errorMessage')}</p>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Metatags title='CEMCA | Nuestra historia' description='Una cámara formada por mujeres.' />
      <Box>
        <Header />
        <Values />
        <OurWork />
        <CoursesBanner />
        <Team />
      </Box>
    </>
  );
};

export default History;
