import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS, DIMENSIONS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  contactrapper: {
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  contactContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginTop: '100px',
    marginBottom: '150px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: 50,
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
      gap: 25,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginTop: '80px',
      marginBottom: '80px',
    },
  },
  infoBox: {
    width: '50%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [themeBreakpoints.down('md')]: {
      display: 'flex',
      width: '100%',
    },
  },
  formBox: {
    width: '45%',
    borderRadius: '10px',
    padding: '30px',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 20px 5px rgba(159, 159, 159, 0.20)',
    [themeBreakpoints.down('md')]: {
      width: '100%',
    },
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formTitle: {
    fontSize: '21px',
    fontWeight: 600,
    color: COLORS.cyan,
    marginBottom: '15px',
  },
  titleInput: {
    fontWeight: 600,
    marginBottom: '5px',
  },
  inputTextBox: {
    marginBottom: '15px',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '30px',
    marginBottom: '15px',
  },
  termsText: {
    color: COLORS.gray,
    textAlign: 'center',
  },
  linksItemsBox: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
    marginBottom: '20px',
    [themeBreakpoints.down('md')]: {
      marginTop: '25px',
    },
  },
  socialBox: {
    display: 'flex',
    gap: 10,
  },
  socialIcon: {
    marginBottom: 8,
    color: COLORS.gray,
    '&:hover': {
      textDecoration: 'none',
      color: COLORS.cyan,
    },
  },
}));

export default styles;
