import { gql, useMutation } from '@apollo/client';
import {
  Alert,
  AlertTitle,
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import clsx from 'clsx';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useFormik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import CustomFunctionButton from '../../components/CustomFunctionButton';
import Metatags from '../../components/MetaTags/metatags';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

dayjs.extend(utc);
dayjs.extend(timezone);

// Graph Mutation
const ADD_AFFILIATE_MUTATION = gql`
  mutation AddAffiliate(
    $name: String!
    $phone: String!
    $email: String!
    $fundation: Date!
    $billingName: String!
    $legalEntityName: String!
    $identification: String!
    $billingPhone: String!
    $billingEmail: String!
    $employees: String!
    $identificationType: String!
    $sector: String!
    $media: String!
    $type: String!
    $billingAddress: String!
  ) {
    createAffiliate(
      data: {
        name: $name
        phone: $phone
        email: $email
        fundation: $fundation
        billingName: $billingName
        legalEntityName: $legalEntityName
        identification: $identification
        billingPhone: $billingPhone
        billingEmail: $billingEmail
        employees: $employees
        identificationType: $identificationType
        sector: $sector
        media: $media
        type: $type
        billingAddress: $billingAddress
      }
    ) {
      data {
        id
        attributes {
          name
          email
          phone
          fundation
          legalEntityName
          identification
          billingPhone
          billingEmail
          employees
          identificationType
          sector
          media
          type
          billingAddress
        }
      }
    }
  }
`;

const Afiliation = () => {
  const classes = styles();
  const fontClasses = fontsStyles();
  const { t } = useTranslation();
  const errorMessageRef = useRef(null);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [addAffiliateForm, { data, loading, error }] = useMutation(ADD_AFFILIATE_MUTATION);
  const handleOpenSuccess = () => {
    setOpenSuccess(true);
  };

  const handleCloseSuccess = (event) => {
    setOpenSuccess(false);
  };

  const scrollToForm = (event) => {
    errorMessageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const Header = () => {
    return (
      <Box className={classes.afiliateWrapper}>
        <Box className={classes.afiliateContentWrapper}>
          <Box className={classes.centerSection}>
            <p className={fontClasses.sectionSubTitles}>{t('afiliationPage.headerSubTitle')}</p>
            <h1 className={clsx(fontClasses.h1, classes.sectionTitleCenter)}>
              {t('afiliationPage.headerTitle')}
            </h1>
            <p className={clsx(fontClasses.p, classes.sectionText)}>
              {t('afiliationPage.headerText')}
            </p>
          </Box>
        </Box>
      </Box>
    );
  };

  const Form = ({ openSucces }) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

    const validationSchema = yup.object({
      name: yup.string().required(t('validationsForm.required')),
      type: yup.string().required(t('validationsForm.required')),
      phone: yup.string().required(t('validationsForm.required')),
      email: yup
        .string()
        .matches(emailRegex, t('validationsForm.validEmail'))
        .required(t('validationsForm.required')),
      employees: yup.string().required(t('validationsForm.required')),
      fundation: yup.string().required(t('validationsForm.required')),
      sector: yup.string().required(t('validationsForm.required')),
      media: yup.string().required(t('validationsForm.required')),
      billingName: yup.string().required(t('validationsForm.required')),
      legalEntityName: yup.string().required(t('validationsForm.required')),
      identificationType: yup.string().required(t('validationsForm.required')),
      identification: yup.string().required(t('validationsForm.required')),
      billingPhone: yup.string().required(t('validationsForm.required')),
      billingEmail: yup
        .string()
        .matches(emailRegex, t('validationsForm.validEmail'))
        .required(t('validationsForm.required')),
      billingAddress: yup.string().required(t('validationsForm.required')),
    });

    useEffect(() => {
      if (!loading && !error && data) {
        openSucces();
        formik.resetForm();
      }
    }, [data]);

    const formik = useFormik({
      initialValues: {
        name: '',
        type: 'Emprendedora',
        phone: '',
        email: '',
        employees: '1 a 5',
        fundation: null,
        sector: 'Agropecuario',
        media: 'Local',
        billingName: '',
        legalEntityName: '',
        identificationType: 'Física',
        identification: '',
        billingPhone: '',
        billingEmail: '',
        billingAddress: '',
      },
      validationSchema,
      onSubmit: async (values) => {
        const formattedDate = dayjs(values.fundation).format('YYYY-MM-DD'); // Format the date here
        await addAffiliateForm({ variables: { ...values, fundation: formattedDate } });
      },
    });

    return (
      <Box className={classes.formWrapper}>
        <Box className={classes.formContentWrapper}>
          <Box className={classes.formSection} ref={errorMessageRef}>
            <p className={clsx(fontClasses.p, classes.formText)}>
              {t('afiliationPage.afiliationText')}
            </p>
            <Alert severity='info'>
              <AlertTitle>{t('afiliationPage.affiliationRequirementTitle')}</AlertTitle>
              {t('afiliationPage.affiliationRequirement')}
            </Alert>
            {formik.submitCount > 0 && Object.keys(formik.errors).length > 0 && (
              <Box sx={{ marginTop: '15px' }}>
                <Alert severity='error'>{t('validationsForm.complete')}</Alert>
              </Box>
            )}
            {error && (
              <Box sx={{ marginTop: '15px' }}>
                <Alert severity='error'>{t('validationsForm.serverError')}</Alert>
              </Box>
            )}
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Box className={classes.formSection}>
              <h4 className={clsx(fontClasses.h4, classes.sectionFormTitle)}>
                {t('afiliationPage.afiliationData')}
              </h4>
              <Box className={classes.formColumns}>
                <Box className={clsx(classes.inputTextBox, classes.inputColumn)}>
                  <p className={clsx(fontClasses.p, classes.titleInput)}>
                    {t('afiliationPage.name')}
                  </p>
                  <TextField
                    fullWidth
                    className={classes.inputText}
                    variant='outlined'
                    id='name'
                    name='name'
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Box>
                <Box className={clsx(classes.inputTextBox, classes.inputColumn)}>
                  <p className={clsx(fontClasses.p, classes.titleInput)}>
                    {t('afiliationPage.type')}
                  </p>
                  <FormControl error={formik.touched.type && Boolean(formik.errors.type)} fullWidth>
                    <Select
                      fullWidth
                      variant='outlined'
                      id='type'
                      name='type'
                      value={formik.values.type}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value={'Emprendedora'}>{t('afiliationPage.entrepreneur')}</MenuItem>
                      <MenuItem value={'Empresaria'}>{t('afiliationPage.businessWoman')}</MenuItem>
                    </Select>
                    <FormHelperText>{formik.touched.type && formik.errors.type}</FormHelperText>
                  </FormControl>
                </Box>
              </Box>
              <Box className={classes.formColumns}>
                <Box className={clsx(classes.inputTextBox, classes.inputColumn)}>
                  <p className={clsx(fontClasses.p, classes.titleInput)}>
                    {t('afiliationPage.phone')}
                  </p>
                  <TextField
                    fullWidth
                    className={classes.inputText}
                    variant='outlined'
                    id='phone'
                    name='phone'
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                </Box>
                <Box className={clsx(classes.inputTextBox, classes.inputColumn)}>
                  <p className={clsx(fontClasses.p, classes.titleInput)}>
                    {t('afiliationPage.email')}
                  </p>
                  <TextField
                    id='email'
                    fullWidth
                    name='email'
                    className={classes.inputText}
                    variant='outlined'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Box>
              </Box>
              <Box className={classes.formColumns}>
                <Box className={clsx(classes.inputTextBox, classes.inputColumn)}>
                  <p className={clsx(fontClasses.p, classes.titleInput)}>
                    {t('afiliationPage.employees')}
                  </p>
                  <FormControl
                    error={formik.touched.employees && Boolean(formik.errors.employees)}
                    fullWidth
                  >
                    <Select
                      fullWidth
                      variant='outlined'
                      id='employees'
                      name='employees'
                      value={formik.values.employees}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value={'1 a 5'}>{t('afiliationPage.1to5')}</MenuItem>
                      <MenuItem value={'6 a 10'}>{t('afiliationPage.6to10')}</MenuItem>
                      <MenuItem value={'Más de 10'}>{t('afiliationPage.plus10')}</MenuItem>
                    </Select>
                    <FormHelperText>
                      {formik.touched.employees && formik.errors.employees}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box className={clsx(classes.inputTextBox, classes.inputColumn)}>
                  <p className={clsx(fontClasses.p, classes.titleInput)}>
                    {t('afiliationPage.fundation')}
                  </p>
                  <DesktopDatePicker
                    sx={{ width: '100%' }}
                    disableFuture
                    orientation='portrait'
                    autoFocus={false}
                    timezone='America/Costa_Rica'
                    value={formik.values.fundation}
                    onChange={(value) => formik.setFieldValue('fundation', value, true)}
                    slotProps={{
                      textField: {
                        name: 'fundation',
                        error: formik.touched.fundation && Boolean(formik.errors.fundation),
                        helperText: formik.touched.fundation && formik.errors.fundation,
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box className={classes.formColumns}>
                <Box className={clsx(classes.inputTextBox, classes.inputColumn)}>
                  <p className={clsx(fontClasses.p, classes.titleInput)}>
                    {t('afiliationPage.sector')}
                  </p>
                  <FormControl
                    error={formik.touched.sector && Boolean(formik.errors.sector)}
                    fullWidth
                  >
                    <Select
                      fullWidth
                      variant='outlined'
                      id='sector'
                      name='sector'
                      value={formik.values.sector}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value={'Agropecuario'}>{t('afiliationPage.agricultural')}</MenuItem>
                      <MenuItem value={'Comercio'}>{t('afiliationPage.commerce')}</MenuItem>
                      <MenuItem value={'Turismo'}>{t('afiliationPage.turism')}</MenuItem>
                      <MenuItem value={'Industria'}>{t('afiliationPage.industry')}</MenuItem>
                      <MenuItem value={'Servicios'}>{t('afiliationPage.services')}</MenuItem>
                      <MenuItem value={'Otros'}>{t('afiliationPage.other')}</MenuItem>
                    </Select>
                    <FormHelperText>{formik.touched.sector && formik.errors.sector}</FormHelperText>
                  </FormControl>
                </Box>
                <Box className={clsx(classes.inputTextBox, classes.inputColumn)}>
                  <p className={clsx(fontClasses.p, classes.titleInput)}>
                    {t('afiliationPage.media')}
                  </p>
                  <FormControl
                    error={formik.touched.media && Boolean(formik.errors.media)}
                    fullWidth
                  >
                    <Select
                      fullWidth
                      variant='outlined'
                      id='media'
                      name='media'
                      value={formik.values.media}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value={'Local'}>{t('afiliationPage.local')}</MenuItem>
                      <MenuItem value={'Ambulante'}>{t('afiliationPage.street')}</MenuItem>
                      <MenuItem value={'Internet (Redes Sociales)'}>
                        {t('afiliationPage.internet')}
                      </MenuItem>
                      <MenuItem value={'Revista o Periódico'}>
                        {t('afiliationPage.newspaper')}
                      </MenuItem>
                      <MenuItem value={'Televisión'}>{t('afiliationPage.tv')}</MenuItem>
                      <MenuItem value={'Otros'}>{t('afiliationPage.other')}</MenuItem>
                    </Select>
                    <FormHelperText>{formik.touched.media && formik.errors.media}</FormHelperText>
                  </FormControl>
                </Box>
              </Box>
            </Box>
            <Box className={classes.formSection}>
              <h4 className={clsx(fontClasses.h4, classes.sectionFormTitle)}>
                {t('afiliationPage.billingDetails')}
              </h4>
              <Box className={classes.formColumns}>
                <Box className={clsx(classes.inputTextBox, classes.inputColumn)}>
                  <p className={clsx(fontClasses.p, classes.titleInput)}>
                    {t('afiliationPage.billingName')}
                  </p>
                  <TextField
                    fullWidth
                    className={classes.inputText}
                    variant='outlined'
                    id='billingName'
                    name='billingName'
                    value={formik.values.billingName}
                    onChange={formik.handleChange}
                    error={formik.touched.billingName && Boolean(formik.errors.billingName)}
                    helperText={formik.touched.billingName && formik.errors.billingName}
                  />
                </Box>
                <Box className={clsx(classes.inputTextBox, classes.inputColumn)}>
                  <p className={clsx(fontClasses.p, classes.titleInput)}>
                    {t('afiliationPage.legalEntityName')}
                  </p>
                  <TextField
                    fullWidth
                    className={classes.inputText}
                    variant='outlined'
                    id='legalEntityName'
                    name='legalEntityName'
                    value={formik.values.legalEntityName}
                    onChange={formik.handleChange}
                    error={formik.touched.legalEntityName && Boolean(formik.errors.legalEntityName)}
                    helperText={formik.touched.legalEntityName && formik.errors.legalEntityName}
                  />
                </Box>
              </Box>
              <Box className={classes.formColumns}>
                <Box className={clsx(classes.inputTextBox, classes.inputColumn)}>
                  <p className={clsx(fontClasses.p, classes.titleInput)}>
                    {t('afiliationPage.identificationType')}
                  </p>
                  <FormControl
                    error={
                      formik.touched.identificationType && Boolean(formik.errors.identificationType)
                    }
                    fullWidth
                  >
                    <Select
                      fullWidth
                      variant='outlined'
                      id='identificationType'
                      name='identificationType'
                      value={formik.values.identificationType}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value={'Física'}>{t('afiliationPage.physical')}</MenuItem>
                      <MenuItem value={'Jurídica'}>{t('afiliationPage.legalId')}</MenuItem>
                      <MenuItem value={'Extranjero'}>{t('afiliationPage.foreign')}</MenuItem>
                    </Select>
                    <FormHelperText>
                      {formik.touched.identificationType && formik.errors.identificationType}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box className={clsx(classes.inputTextBox, classes.inputColumn)}>
                  <p className={clsx(fontClasses.p, classes.titleInput)}>
                    {t('afiliationPage.identification')}
                  </p>
                  <TextField
                    fullWidth
                    className={classes.inputText}
                    variant='outlined'
                    id='identification'
                    name='identification'
                    value={formik.values.identification}
                    onChange={formik.handleChange}
                    error={formik.touched.identification && Boolean(formik.errors.identification)}
                    helperText={formik.touched.identification && formik.errors.identification}
                  />
                </Box>
              </Box>
              <Box className={classes.formColumns}>
                <Box className={clsx(classes.inputTextBox, classes.inputColumn)}>
                  <p className={clsx(fontClasses.p, classes.titleInput)}>
                    {t('afiliationPage.billingPhone')}
                  </p>
                  <TextField
                    fullWidth
                    className={classes.inputText}
                    variant='outlined'
                    id='billingPhone'
                    name='billingPhone'
                    value={formik.values.billingPhone}
                    onChange={formik.handleChange}
                    error={formik.touched.billingPhone && Boolean(formik.errors.billingPhone)}
                    helperText={formik.touched.billingPhone && formik.errors.billingPhone}
                  />
                </Box>
                <Box className={clsx(classes.inputTextBox, classes.inputColumn)}>
                  <p className={clsx(fontClasses.p, classes.titleInput)}>
                    {t('afiliationPage.billingEmail')}
                  </p>
                  <TextField
                    fullWidth
                    className={classes.inputText}
                    variant='outlined'
                    id='billingEmail'
                    name='billingEmail'
                    value={formik.values.billingEmail}
                    onChange={formik.handleChange}
                    error={formik.touched.billingEmail && Boolean(formik.errors.billingEmail)}
                    helperText={formik.touched.billingEmail && formik.errors.billingEmail}
                  />
                </Box>
              </Box>
              <Box className={clsx(classes.inputTextBox)}>
                <p className={clsx(fontClasses.p, classes.titleInput)}>
                  {t('afiliationPage.billingAddress')}
                </p>
                <TextField
                  fullWidth
                  className={classes.inputText}
                  variant='outlined'
                  id='billingAddress'
                  name='billingAddress'
                  value={formik.values.billingAddress}
                  onChange={formik.handleChange}
                  error={formik.touched.billingAddress && Boolean(formik.errors.billingAddress)}
                  helperText={formik.touched.billingAddress && formik.errors.billingAddress}
                />
              </Box>
            </Box>
            <Box className={classes.formButtons}>
              <CustomFunctionButton
                label={t('afiliationPage.actionButton')}
                handlerFun={scrollToForm}
              />
              <p className={clsx(fontClasses.terms_conditionsFoms)}>{t('afiliationPage.terms')}</p>
            </Box>
          </form>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Metatags
        title='CEMCA | Formulario de afiliación'
        description='Completa el formulario y únete a nuestra red de mujeres emprendedoras hoy mismo.'
      />
      <Header />
      <Form openSucces={handleOpenSuccess} />
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSuccess} severity='success' sx={{ width: '100%' }}>
          {t('afiliationPage.succesMessage')}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Afiliation;
