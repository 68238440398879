import 'react-responsive-carousel/lib/styles/carousel.min.css';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import clsx from 'clsx';
import { isEmptyArray } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Carousel } from 'react-responsive-carousel';

import fontsStyles from '../../styles/fontStyles';
import useStyles from './styles';

const ProjectCard = ({
  index,
  title,
  images,
  summary,
  ideas,
  beneficiaries,
  months,
  editions,
  communityTotal,
  zones,
  tag,
  inProgress,
}) => {
  const fontClasses = fontsStyles();
  const classes = useStyles();
  const cyanStyle = index % 2 === 0;
  const isDesktop = useMediaQuery({ query: '(min-width: 1100px)' });
  const { t } = useTranslation();

  return (
    <Box className={clsx(classes.projectWrapper, !cyanStyle ? classes.projectWrapperWhite : '')}>
      <Box className={classes.projectContentWrapper}>
        {!cyanStyle && isDesktop && (
          <Box className={classes.carrouselBox}>
            <Carousel
              autoPlay
              dynamicHeight
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              infiniteLoop
              className={classes.carrouselStyle}
              renderArrowPrev={(clickHandler) => {
                return (
                  <Box className={classes.leftArrowBox} onClick={clickHandler}>
                    <ChevronLeftIcon className={classes.arrows} />
                  </Box>
                );
              }}
              renderArrowNext={(clickHandler) => {
                return (
                  <Box className={classes.rightArrowBox} onClick={clickHandler}>
                    <ChevronRightIcon className={classes.arrows} />
                  </Box>
                );
              }}
            >
              {!isEmptyArray(images.data) &&
                images.map((image, index) => {
                  return (
                    <img
                      key={index}
                      src={image.attributes.url}
                      className={classes.projectImg}
                      alt='Imagen de proyecto'
                    />
                  );
                })}
            </Carousel>
          </Box>
        )}
        <Box className={classes.contentBox}>
          <Box>
            <h1 className={fontClasses.projectTitle}>{title}</h1>
            {tag && inProgress && <Chip label={tag} sx={{ marginTop: '15px' }} />}
            {zones && (
              <Box className={classes.zonesBox}>
                {zones.map((mapZones, index) => {
                  return (
                    <h4 key={index} className={fontClasses.projectZones}>
                      {mapZones.zone}
                    </h4>
                  );
                })}
              </Box>
            )}
            {summary && (
              <Box className={classes.summaryBox}>
                <p className={fontClasses.p}>{summary}</p>
              </Box>
            )}
            <Box className={classes.indicatorsBox}>
              {ideas && (
                <Box>
                  <p className={clsx(fontClasses.h1, classes.indicatorsContent)}>{ideas}</p>
                  <p className={clsx(fontClasses.p, classes.indicatorsTitle)}>
                    {t('projectsPage.ideas')}
                  </p>
                </Box>
              )}
              {beneficiaries && (
                <Box>
                  <p className={clsx(fontClasses.h1, classes.indicatorsContent)}>{beneficiaries}</p>
                  <p className={clsx(fontClasses.p, classes.indicatorsTitle)}>
                    {t('projectsPage.beneficiaries')}
                  </p>
                </Box>
              )}
              {months && (
                <Box>
                  <p className={clsx(fontClasses.h1, classes.indicatorsContent)}>{months}</p>
                  <p className={clsx(fontClasses.p, classes.indicatorsTitle)}>
                    {t('projectsPage.months')}
                  </p>
                </Box>
              )}
              {editions && (
                <Box>
                  <p className={clsx(fontClasses.h1, classes.indicatorsContent)}>{editions}</p>
                  <p className={clsx(fontClasses.p, classes.indicatorsTitle)}>
                    {t('projectsPage.editions')}
                  </p>
                </Box>
              )}
              {communityTotal && (
                <Box>
                  <p className={clsx(fontClasses.h1, classes.indicatorsContent)}>
                    {communityTotal}
                  </p>
                  <p className={clsx(fontClasses.p, classes.indicatorsTitle)}>
                    {t('projectsPage.communities')}
                  </p>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        {(cyanStyle || !isDesktop) && (
          <Box className={classes.carrouselBox}>
            <Carousel
              autoPlay
              dynamicHeight
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              infiniteLoop
              className={classes.carrouselStyle}
              renderArrowPrev={(clickHandler) => {
                return (
                  <Box className={classes.leftArrowBox} onClick={clickHandler}>
                    <ChevronLeftIcon className={classes.arrows} />
                  </Box>
                );
              }}
              renderArrowNext={(clickHandler) => {
                return (
                  <Box className={classes.rightArrowBox} onClick={clickHandler}>
                    <ChevronRightIcon className={classes.arrows} />
                  </Box>
                );
              }}
            >
              {!isEmptyArray(images.data) &&
                images.map((image, index) => {
                  return (
                    <img
                      key={index}
                      src={image.attributes.url}
                      className={classes.projectImg}
                      alt='Imagen de proyecto'
                    />
                  );
                })}
            </Carousel>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProjectCard;
