// import EmailIcon from '@mui/icons-material/Email';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import fontsStyles from '../../styles/fontStyles';
import useStyles from './styles';

const TeamMember = ({ member }) => {
  const fontClasses = fontsStyles();
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.memberBox}>
      <Box className={classes.coverImgBox}>
        <img src={member.attributes.photo.data.attributes.url} className={classes.coverImg} />
      </Box>
      <Box className={classes.memberInformartion}>
        <p className={clsx(fontClasses.p, classes.memberTitle)}>{member.attributes.name}</p>
        <p className={clsx(fontClasses.p, classes.memberPosition)}>{member.attributes.position}</p>
        <p className={clsx(fontClasses.p, classes.memberCompany)}>
          <b>{`${t('historyPage.business')}: `}</b>
          {member.attributes.business}
        </p>
        <p className={clsx(fontClasses.p, classes.memberCompany)}>
          <b>{`${t('historyPage.experience')}: `}</b>
        </p>
        {member.attributes.experience.map((exp, index) => {
          return (
            <p key={index} className={clsx(fontClasses.p, classes.memberExperience)}>
              {'\u2022 '}
              {exp}
            </p>
          );
        })}

        {/* 
        <Box className={classes.socialBox}>
          {member.social.email && (
            <a
              href={member.social.email}
              rel='noreferrer'
              target='_blank'
              className={classes.socialIcon}
            >
              <EmailIcon />
            </a>
          )}
          {member.social.linkedin && (
            <a
              href={member.social.linkedin}
              rel='noreferrer'
              target='_blank'
              className={classes.socialIcon}
            >
              <LinkedInIcon />
            </a>
          )}
          </Box> 
          */}
        <p className={clsx(fontClasses.p, classes.memberText)}></p>
      </Box>
    </Box>
  );
};

export default TeamMember;
