import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Grid } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

import { PATHS } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import SeminarInformation from '../SeminarInformation';
import useStyles from './styles';

export default function SeminarPageHeader({ backTo, seminar }) {
  const classes = useStyles();
  const fontClasses = fontsStyles();

  return (
    <Box className={classes.headerWrapper}>
      <Box className={classes.headerContentWrapper}>
        <Grid container spacing={2}>
          <Grid item md={7} xs={12}>
            <Box>
              <Link to={PATHS.courses} className={classes.pathLink}>
                <p className={clsx(fontClasses.p, classes.routeTextLink)}>
                  <ArrowBackIcon />
                  {backTo}
                </p>
              </Link>
              <h2 className={clsx(fontClasses.h2, classes.routeTile)}>
                {seminar.attributes.title}
              </h2>
            </Box>
          </Grid>
          <Grid item md={5} xs={12} style={{ maxHeight: 0 }}>
            <SeminarInformation
              seminarImg={seminar.attributes.image.data.attributes.url}
              startDate={seminar.attributes.dates}
              modality={seminar.attributes.modality}
              price={seminar.attributes.price}
              duration={seminar.attributes.duration}
              phone={seminar.attributes.phone_numbers}
              mail={seminar.attributes.email}
              minimumCapacity={seminar.attributes.minimumCapacity}
              enrollmentStart={seminar.attributes.enrollmentStart}
              enrollmentEnd={seminar.attributes.enrollmentEnd}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
