import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS, DIMENSIONS } from '../../constants/';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles((theme) => ({
  navbarWrapper: {
    backgroundColor: COLORS.cyan,
    height: '35px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 500,
    position: 'sticky',
    top: 0,
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  navbarContent: {
    width: DIMENSIONS.MAIN_WRAPPER,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 10,
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      padding: '5px 10px',
    },
  },
  wheatherBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  languageButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: COLORS.yellow,
    },
  },
}));

export default useStyles;
