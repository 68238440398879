import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles((theme) => ({
  memberBox: {
    width: '350px',
    height: '100%',
    backgroundColor: COLORS.white,
    borderRadius: '10px',
    boxShadow: '0px 5px 20px 5px rgba(159, 159, 159, 0.20)',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
      opacity: '0.9',
    },
  },
  coverImgBox: {
    width: '100%',
    [themeBreakpoints.down('md')]: {
      // width: '40%',
    },
  },
  coverImg: {
    width: '100%',
    height: '350px',
    objectFit: 'cover',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
  memberInformartion: {
    boxSizing: 'border-box',
    padding: '20px',
    [themeBreakpoints.down('sm')]: {
      padding: '15px',
      width: '100%',
    },
  },
  memberTitle: {
    fontWeight: 600,
  },
  memberPosition: {
    fontWeight: 400,
    color: COLORS.cyan,
    marginBottom: '10px',
  },
  memberCompany: {
    fontWeight: 400,
    fontSize: '13px',
    marginBottom: '5px',
  },
  memberText: {
    fontWeight: 400,
    fontSize: '14px',
    textAlign: 'justify',
  },
  memberExperience: {
    fontWeight: 400,
    fontSize: '14px',
    textIndent: -10,
    marginLeft: '10px',
  },
  socialBox: {
    display: 'flex',
    gap: 10,
    marginBottom: '10px',
  },
  socialIcon: {
    textDecoration: 'none',
    color: COLORS.gray,
    cursor: 'pointer',
    '&:hover': {
      color: COLORS.cyan,
    },
  },
}));

export default useStyles;
